const resetAuthData = () => {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("tokenExpire");
  localStorage.removeItem("activePlan");
  localStorage.removeItem("planCanceled");
  localStorage.removeItem("planCanceledAt");
  localStorage.removeItem("planEnd");
  localStorage.removeItem("planStatus");
  sessionStorage.removeItem("tempTheme");
  sessionStorage.removeItem("tempOrg");
  if (window.productFruits) {
    window.productFruits.services.destroy();
  }
  window.location.reload();
};

export default resetAuthData;

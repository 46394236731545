import { Notification, Tag } from "rsuite";
import i18n from "../../i18n";

const setNotification = (
  title,
  text,
  highlight,
  button,
  buttonPath,
  duration,
  text2,
  list
) => {
  Notification.open({
    title: i18n.t(title),
    description: (
      <div className="general-notification">
        <h4>
          {i18n.t(text)}
          {highlight && <strong> {i18n.t(highlight)}</strong>}
        </h4>
        {text2 && (
          <h4>
            <strong>{i18n.t(text2)}</strong>
          </h4>
        )}
        {list && (
          <div className="notification-error-list">
            {list.map((item) => (
              <Tag>{i18n.t(item)}</Tag>
            ))}
          </div>
        )}
        {button && (
          <div className="notification-buttons">
            <button
              className="action-button-2"
              onClick={() => Notification.close()}
            >
              {i18n.t(
                "criar_e_gerir_parâmetros_de_campanha.notification.button.close"
              )}
            </button>
            <button
              className="action-button no-margin"
              onClick={() => {
                buttonPath();
                Notification.close();
              }}
            >
              {i18n.t(button)}
            </button>
          </div>
        )}
      </div>
    ),
    duration: 0,
  });
};

export default setNotification;

import axios from "axios";
import qs from "qs";
import { setDebugNotification } from "../helpers/notifications/debug";

const authService = axios.create({
  baseURL: process.env.REACT_APP_FB_URI,
});

const bitlyService = axios.create({
  baseURL: "https://api-ssl.bitly.com",
});

const tinyURLService = axios.create({
  baseURL: "https://api.tinyurl.com/",
});

axios.defaults.headers = {};

const getOrganizationData = (token) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .get("/organizations", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const editOrganization = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/update-organizations", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const updateOrgImage = (token, body, id) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/organization/image", body, {
      headers: { Authorization: `Bearer ${token}`, Organization: id },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const bitlyAuthenticate = (body) => {
  return bitlyService
    .post("/oauth/access_token", qs.stringify(body), {
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
    })
    .then((response) => {
      //console.log("success", response);
      return Promise.resolve(response);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const bitlyGroups = (token) => {
  return bitlyService
    .get("/v4/groups", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      //console.log("success", response);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const shortenURL = (token, body) => {
  return bitlyService
    .post("/v4/shorten", body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      //console.log("success", response);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      //console.log("error", error);
      return Promise.reject(error);
    });
};

const createTinyURL = (token, body) => {
  return tinyURLService
    .post("/create", body, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      //console.log("success", response);
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      //console.log("error", error);
      return Promise.reject(error);
    });
};

const getBitlyClicks = (token, body) => {
  return bitlyService
    .get(`v4/bitlinks/${body}/clicks/summary`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      //console.log("error", error);
      return Promise.reject(error);
    });
};

const getBitlyUser = (token) => {
  return bitlyService
    .get(`v4/user`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      //console.log("error", error);
      return Promise.reject(error);
    });
};
const getBitlyOrg = (token) => {
  return bitlyService
    .get(`v4/organizations`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      //console.log("error", error);
      return Promise.reject(error);
    });
};
const getBitlyGroups = (token) => {
  return bitlyService
    .get(`v4/groups`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      //console.log("error", error);
      return Promise.reject(error);
    });
};

const getBitlyLimit = (token, org) => {
  return bitlyService
    .get(`v4/organizations/${org}/plan_limits`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      //console.log("error", error);
      return Promise.reject(error);
    });
};

const getPiwikAuth = (account, id, secret) => {
  return axios
    .create({
      baseURL: `https://${account}`,
    })
    .post(
      `/auth/token`,
      {
        grant_type: "client_credentials",
        client_id: id,
        client_secret: secret,
      },
      {
        headers: {},
      }
    )
    .then((response) => {
      return Promise.resolve(response.data);
    })
    .catch((error) => {
      //console.log("error", error);
      return Promise.reject(error);
    });
};

export {
  getOrganizationData,
  editOrganization,
  updateOrgImage,
  bitlyAuthenticate,
  bitlyGroups,
  shortenURL,
  createTinyURL,
  getBitlyClicks,
  getBitlyUser,
  getBitlyOrg,
  getBitlyGroups,
  getBitlyLimit,
  getPiwikAuth,
};

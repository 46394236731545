import axios from "axios";
import { setDebugNotification } from "../helpers/notifications/debug";

const authService = axios.create({
  baseURL: process.env.REACT_APP_FB_URI,
});

const getUserTeamsData = (token, org) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .get(`/teams/${org}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const createTeam = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/teams", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const editTeam = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/update-team", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const deleteTeam = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/delete-team", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

export { getUserTeamsData, createTeam, editTeam, deleteTeam };

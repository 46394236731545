import axios from "axios";
import { setDebugNotification } from "../helpers/notifications/debug";

const authService = axios.create({
  baseURL: process.env.REACT_APP_FB_URI,
});

const login = (values) => {
  const body = { email: values.email, password: values["password"] };
  if (!values.email || !values["password"]) {
    return;
  }
  return authService
    .post("/login", body)
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const refreshSession = (token) => {
  if (!token) {
    return Promise.reject("No Token");
  }
  return authService
    .post("/refresh-user", { refreshToken: token })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const getUserData = (token) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .get("/user", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const getUsersData = (token, orgId) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .get(`/users/${orgId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const getUserBillingData = (token, orgId) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .get(`/user/details/${orgId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const getUserPaymentData = (token, orgId) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .get(`/user/cards/${orgId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};
const getUserTaxStatus = (token, orgId) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .get(`/user/validate/${orgId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const sendUserInvite = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/register", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const resendUserInvite = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/resend-invite", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const confirmUser = (body) => {
  return authService
    .post("/confirm", body)
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const updateUser = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/update-user", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const updateUserPassword = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/update-password", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const updateUserImage = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/user/image", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const updateMultipleUsers = (token, body) => {
  if (!token) {
    return Promise.reject("No token");
  }
  return authService
    .post("/user/update", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const sendRecoveryEmail = (body) => {
  return authService
    .post("/reset-password", body)
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const confirmRecovery = (body) => {
  return authService
    .post("/reset-password-confirm", body)
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const createAccount = (body) => {
  return authService
    .post("/account", body)
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const confirmAccount = (body) => {
  return authService
    .post("/account-confirm", body)
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

const deleteUser = (token, body) => {
  return authService
    .post("/delete-user", body, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((response) => {
      const data = response.data;
      return Promise.resolve(data);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject(error);
    });
};

export {
  login,
  refreshSession,
  getUserData,
  getUsersData,
  getUserBillingData,
  getUserPaymentData,
  getUserTaxStatus,
  sendUserInvite,
  resendUserInvite,
  confirmUser,
  updateUser,
  deleteUser,
  updateUserImage,
  updateMultipleUsers,
  sendRecoveryEmail,
  confirmRecovery,
  createAccount,
  confirmAccount,
  updateUserPassword,
};

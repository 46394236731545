import { Alert } from "rsuite";
import i18n from "../../i18n";
import resetAuthData from "../authDataReset";
import { setDebugNotification } from "./debug";

const setAlert = (message, code, type) => {
  const debug = sessionStorage.getItem("debugMode") === "true";
  //If session has expired, reload page and redirect to login page
  if (code === "auth/id-token-expired" || code === "auth/argument-error") {
    window.location.reload();
    return;
  }

  //If no active plan, reload and redirect to plan subscription page
  if (code === "no-plan") {
    message = "An active plan is required to continue using URL Builder Online";
    window.setTimeout(() => {
      resetAuthData();
    }, 3000);
  }
  switch (type) {
    case "success":
      Alert.success(i18n.t(message), 3000);
      break;
    case "error":
      Alert.error(i18n.t(message), 3000);
      if (debug) setDebugNotification(code);
      break;
    default:
      Alert.info(i18n.t(message), 3000);
      break;
  }
};

export default setAlert;

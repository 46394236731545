import { getOrganizationData } from "../../services/organization";
import { setDebugNotification } from "../notifications/debug";

const getOrganizationInformation = (token) => {
  return getOrganizationData(token)
    .then((response) => {
      //In case of an empty response, return a rejected promise
      if (!response || !response.length)
        return Promise.reject("No data retrieved");

      //Initialize the main organization object
      const baseOrg = {
        capitalization: false,
        syntax_killer: false,
        utm_separator: " ",
        forbidden_chars: [],
        link_shorteners: [],
      };

      //Set the organization ID to the response organizationId
      baseOrg.id = response[0].organizationId;

      //Set the organization's piwik data
      baseOrg.piwik = {
        acc: response[0].piwik_acc,
        id: response[0].piwik_id,
        secret: response[0].piwik_secret,
      };

      //Spread the data in response into the organization object
      const org = { ...baseOrg, ...response[0] };

      return Promise.resolve(org);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject("Error getting organizations information");
    });
};

export { getOrganizationInformation };

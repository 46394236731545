//Function that takes an http protocol, a base url, a parameter list and a hash and returns an encoded url with the parameters as query string and the hash as a hash
export const linkBuilder = (
  protocol,
  baseUrl,
  standardParams,
  customParams,
  disableEncoding
) => {
  //Filter out the parameters that are not set
  standardParams = Object.fromEntries(
    Object.entries(standardParams).filter(([, value]) => value)
  );
  //Split the base url in case it has a hash
  let splitBaseUrl = baseUrl ? baseUrl.split("#") : "";

  //Set the base url to the protocol and the first part of the base url
  let url = `${protocol || "https"}://${splitBaseUrl ? splitBaseUrl[0] : ""}`;

  //If there are any parameters add them to the url
  if (
    Object.values(standardParams).length ||
    Object.values(customParams).length
  ) {
    //Initialize the query string with a ?
    url += url.includes("?") ? "&" : `?`;
    //Add the standard parameters
    for (const param in standardParams) {
      if (standardParams[param]) url += `${param}=${standardParams[param]}&`;
    }
    //Add the custom parameters
    for (const param in customParams) {
      if (customParams[param]) url += `${param}=${customParams[param]}&`;
    }
  }
  //If the last character is a & remove it
  if (url[url.length - 1] === "&") {
    url = url.slice(0, -1);
  }
  //If there is a hash add it to the url
  if (splitBaseUrl[1]) {
    url += `#${splitBaseUrl[1]}`;
  }
  //Return the encoded url
  return disableEncoding ? url : encodeURI(url);
};

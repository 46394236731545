import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  Button,
  Divider,
  Drawer,
  Icon,
  IconButton,
  Tag,
  Timeline,
  Toggle,
} from "rsuite";
import {
  getCLS,
  getFCP,
  onCLS,
  onFCP,
  onFID,
  onINP,
  onLCP,
  onTTFB,
} from "web-vitals";
import reportWebVitals from "../../reportWebVitals";
import "./style.less";

function DebugMenu() {
  const [open, setOpen] = React.useState(false);
  const [debug, setDebug] = React.useState(false);
  const [webVitals, setWebVitals] = React.useState({
    cls: { name: "CLS", value: 0 },
    fcp: { name: "FCP", value: 0 },
    lcp: { name: "LCP", value: 0 },
    fid: { name: "FID", value: 0 },
    ttfb: { name: "TTFB", value: 0 },
    inp: { name: "INP", value: 0 },
  });
  const [errorLog, setErrorLog] = React.useState([]);

  const location = useLocation();

  useEffect(() => {
    let allVitals = { ...webVitals };
    onCLS((vitals) => {
      allVitals.cls = vitals;
      setWebVitals(allVitals);
    });
    onLCP((vitals) => {
      allVitals.lcp = vitals;
      setWebVitals(allVitals);
    });
    onFCP((vitals) => {
      allVitals.fcp = vitals;
      setWebVitals(allVitals);
    });
    onTTFB((vitals) => {
      allVitals.ttfb = vitals;
      setWebVitals(allVitals);
    });
    onINP((vitals) => {
      allVitals.inp = vitals;
      setWebVitals(allVitals);
    });
    onFID((vitals) => {
      allVitals.fid = vitals;
      setWebVitals(allVitals);
    });
  }, [location]);

  useEffect(() => {
    if (!open) return;
    //Get debugErrors from sessionStorage
    const debugErrors = JSON.parse(sessionStorage.getItem("debugErrors"));
    if (debugErrors) {
      setErrorLog(debugErrors);
    }

    //Get debugMode from sessionStorage
    const debugEnabled = sessionStorage.getItem("debugMode");
    if (debugEnabled) {
      setDebug(true);
    } else {
      setDebug(false);
    }
  }, [open]);

  const toggleDebug = () => {
    //Check if the debug flag is enabled in sessionStorage
    const debugEnabled = sessionStorage.getItem("debugMode");
    if (debugEnabled) {
      //If it is, remove it and disable debug mode
      sessionStorage.removeItem("debugMode");
      setDebug(false);
    } else {
      //If it isn't, add it and enable debug mode
      sessionStorage.setItem("debugMode", true);
      setDebug(true);
    }
  };

  return (
    <>
      <Drawer
        backdrop={false}
        show={open}
        onHide={() => setOpen(false)}
        className="debug-menu"
      >
        <Drawer.Header>
          <Drawer.Title>Debug Menu</Drawer.Title>
          <Divider />
        </Drawer.Header>
        <Drawer.Body>
          <div className="debug-toggle">
            <h4>Enable Debug Mode?</h4>
            <Toggle
              checked={debug}
              onChange={() => {
                toggleDebug();
              }}
            />
          </div>
          <div className="debug-core-vitals">
            <h3>Core Vitals - {location.pathname} </h3>
            <div className="debug-core-vitals-list">
              {Object.values(webVitals).map((vital) => (
                <div className={`debug-core-vitals-item ${vital.rating}`}>
                  <h4>{vital.name}</h4>
                  <h3>{vital.value.toFixed(2)}</h3>
                  <Tag>
                    <h4>
                      {vital.rating === "good" && "GOOD"}
                      {vital.rating === "needs-improvement" && "OKAY"}
                      {vital.rating === "poor" && "POOR"}
                      {!vital.rating && "NO DATA"}
                    </h4>
                  </Tag>
                </div>
              ))}
            </div>
          </div>
          <div className="debug-performance-timing">
            <div className="error-log-header">
              <h3>Error Log</h3>
              <button
                className="action-button-2"
                onClick={() => {
                  //Remove debugErrors from sessionStorage
                  sessionStorage.removeItem("debugErrors");
                  //Clear the error log
                  setErrorLog([]);
                }}
              >
                Clear Log
              </button>
            </div>
            <div className="debug-error-list">
              <Timeline>
                {errorLog.map((error) => (
                  <Timeline.Item>
                    <div className="debug-error-item">
                      <Tag>{error.date}</Tag> - {error.error}
                    </div>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          </div>
        </Drawer.Body>
        <Drawer.Footer></Drawer.Footer>
      </Drawer>

      <IconButton
        className="debug-icon"
        icon={<Icon icon="cog" />}
        onClick={() => setOpen(true)}
      />
    </>
  );
}

export default DebugMenu;

import React, { useState } from "react";
import SlidingDrawer from "..";
import { Link, withRouter } from "react-router-dom";
import permissionsManager from "../../../../helpers/permissionsManager";
import { Nav } from "rsuite";
import "rsuite/lib/Nav/styles/";
import "./style.css";
import { Translation } from "react-i18next";
function Sidenav(props) {
  const [navTab, setNavTab] = useState("");
  const [expanded, setExpanded] = useState(true);
  return (
    <Translation>
      {(t) => (
        <div className="sidenav-wrapper">
          <div className="sidenav">
            <div className="org-img">
              <img
                src={
                  (props.organization && props.organization.imageUrl) ||
                  "https://firebasestorage.googleapis.com/v0/b/timesheet-2d650.appspot.com/o/12854244.png?alt=media"
                }
                alt="logo"
              />
            </div>
            {/*
        <div className="team-initials">
          <h4>PM</h4>
        </div>
         */}
            <div className="main-nav">
              <Nav
                className="tab-nav"
                appearance="tabs"
                vertical
                activeKey={navTab}
                onSelect={() => setExpanded(true)}
              >
                <Nav.Item
                  className="tab-nav-item"
                  eventKey="dashboard"
                  onSelect={() => props.history.push("/")}
                >
                  <h4 className="icon icon-drawer"></h4>
                  {t("menu.barra_esquerda.level_1.dashboard")}
                </Nav.Item>
                <Nav.Item
                  className="tab-nav-item"
                  eventKey="campaigns"
                  onSelect={() => setNavTab("campaigns")}
                >
                  <h4 className="icon icon-drawer"></h4>
                  {t("menu.barra_esquerda.level_2.camps")}
                </Nav.Item>
                <Nav.Item
                  className="tab-nav-item"
                  eventKey="links"
                  onSelect={() => setNavTab("links")}
                >
                  <h4 className="icon icon-drawer"></h4>
                  {t("menu.barra_esquerda.level_2.lis")}
                </Nav.Item>
                {permissionsManager("org", props.orgAdmin, "manageAllUsers") ||
                  (permissionsManager(
                    "org",
                    props.orgRole,
                    "manageAllUsers"
                  ) && (
                    <Nav.Item
                      className="tab-nav-item"
                      eventKey="teams"
                      onSelect={() => setNavTab("teams")}
                    >
                      <h4 className="icon icon-drawer"></h4>
                      {t("menu.barra_esquerda.level_2.teams")}
                    </Nav.Item>
                  ))}
                {(permissionsManager(
                  "team",
                  props.teamRole,
                  "viewCampaignLinkTrash"
                ) ||
                  permissionsManager(
                    "team",
                    props.teamRole,
                    "viewTemplateTrash"
                  )) && (
                  <Nav.Item
                    className="tab-nav-item"
                    eventKey="trash"
                    onSelect={() => setNavTab("trash")}
                  >
                    <h4 className="icon icon-drawer"></h4>
                    {t("menu.barra_esquerda.level_1.trash")}
                  </Nav.Item>
                )}
              </Nav>
              <Nav
                className="tab-nav"
                appearance="tabs"
                vertical
                activeKey={navTab}
                onSelect={() => setExpanded(true)}
              >
                {/*
            <Nav.Item
              className="tab-nav-item"
              eventKey="help"
              onSelect={() => setNavTab("help")}
            >
              <h4 className="icon icon-drawer"></h4>
              {t("menu.barra_esquerda.level_1.help")}
            </Nav.Item>
            */}
                <Nav.Item
                  className="tab-nav-item"
                  eventKey="settings"
                  onSelect={() => setNavTab("settings")}
                >
                  <h4 className="icon icon-drawer"></h4>
                  {t("menu.barra_esquerda.level_1.config")}
                </Nav.Item>
              </Nav>
            </div>
            <div className="upgrade-container">
              {props.orgRole && (
                <Link to="/settings/billings/plans">
                  <div className="upgrade-button">
                    <h4>
                      {t("menu.barra_esquerda.level_1.upgrade").toUpperCase()}
                    </h4>
                  </div>
                </Link>
              )}
            </div>
          </div>
          <SlidingDrawer
            setExpanded={setExpanded}
            expanded={expanded}
            drawer={navTab}
            user={props.user}
            organization={props.organization}
            changeTeam={props.changeTeam}
            teams={props.teams}
            teamSelected={props.teamSelected}
            teamRole={props.teamRole}
            orgRole={props.orgRole}
            view={props.view}
            plan={props.plan}
            setNavTab={setNavTab}
            version={props.version}
            versionDate={props.versionDate}
          ></SlidingDrawer>
        </div>
      )}
    </Translation>
  );
}

export default withRouter(Sidenav);

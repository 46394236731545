import { css } from "@emotion/react";
import React, { useState } from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import { PulseLoader } from "react-spinners";
import { Button, Checkbox, Container, Divider, Icon, Message, Steps } from "rsuite";
import "./style.css";

const override = css`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
`;

function SignupTCs(props) {
	const title = props.title;
	const confirmButton = props.confirmButton;
	const options = props.options;
	const loading = props.loading;
	const [terms, acceptTerms] = useState(false);
	return (
		<Translation>
			{(t) => (
				<Container>
					<h3>{t(title)}</h3>
					<div className="auth-form">
						{props.error && <Message type="error" description={props.error} />}
						{props.info}
					</div>
				</Container>
			)}
		</Translation>
	);
}

export default SignupTCs;

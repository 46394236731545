/* eslint-disable jsx-a11y/anchor-is-valid */
import { css } from "@emotion/react";
import React, { useState, useEffect, createContext } from "react";
import ReactTooltip from "react-tooltip";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import PulseLoader from "react-spinners/PulseLoader";
import {
  SelectPicker,
  InputPicker,
  Input,
  TagPicker,
  DatePicker,
  InputGroup,
  Checkbox,
  Divider,
  Tag,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Icon,
  Toggle,
} from "rsuite";
import "./style.css";
import "rsuite/lib/SelectPicker/styles/";
import "rsuite/lib/InputPicker/styles/";
import "rsuite/lib/TagPicker/styles/";
import "rsuite/lib/DatePicker/styles/";
import "rsuite/lib/InputGroup/styles/";
import UpgradeRequired from "../UpgradeRequired";
import planManager from "../../helpers/organizationPlanData";
import { linkBuilder } from "../../helpers/linkBuilder";
import { passwordRegex } from "../../helpers/regularExpressions";
import setAlert from "../../helpers/notifications/alerts";

const PopupContext = createContext(null);

function Popup(props) {
  const [loading, setLoading] = useState(false);
  const [formVals, inputChange] = useState({ ...props.values });
  const [tempVals, tempChange] = useState({});
  const [concatonatedParamVals, valsChange] = useState({});
  const [customParams, setCustomParams] = useState([]);
  const [teamPermissions, setTeamPermissions] = useState({});
  const [step, setStep] = useState(1);
  const [error, setError] = useState("");
  const returnURL = props.data.returnURL;
  const title = props.data.title;
  const itemData = props.data.popupItems;
  const additionalStepItemData = props.data.additionalStepItems;
  const submit = props.data.submit;
  const additionalSteps = props.data.additionalSteps;
  const hasOutput = props.data.hasOutput;
  const hasTaxonomy = props.data.hasTaxonomy;
  const taxonomy = props.data.taxonomy;
  const required = props.data.required;
  const sessionItems = window.sessionStorage.getItem("formValues");
  const sessionConcatItems = window.sessionStorage.getItem("concatValues");
  const sessionCustomItems = window.sessionStorage.getItem("customValues");

  const override = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `;

  useEffect(() => {
    restoreFormValues();
    if (props.values) {
      let custom = [];
      let values = Object.entries(props.values);
      let customParameters = props.data.popupItems.find(
        (item) => item.type === "custom_parameters"
      );

      if (customParameters && !customParams.length) {
        customParameters = customParameters.fields.map((field) => field.name);
        customParameters.forEach((param) => {
          if (props.values[param]) {
            custom.push({ name: param, parameter: props.values[param] });
          }
        });
        setCustomParams(custom);
      }

      if (props.values.teamPermissions && props.values.teamPermissions.length) {
        let permissions = {};
        props.values.teamPermissions.forEach(
          (team) => (permissions[team.id] = team.role)
        );
        setTeamPermissions(permissions);
      }
    }
  }, [props.values]);

  useEffect(() => {
    setError(props.error);
    setLoading(false);
  }, [props.error]);

  const handleInputChange = async (
    event,
    item,
    concatonatedParamType,
    concatonatedSeparator,
    create,
    concatFields
  ) => {
    setError("");
    let name = event.target.name;
    let value = event.target.value;
    if (value === null) {
      value = "";
    }
    if (value && props.createParam && create) {
      await props
        .createParam(value, item.name)
        .then((val) => {
          if (val !== null) {
            value = val.name;
            item.items.push(val);
            item.loading = false;
          }
        })
        .catch((error) => {
          value = "";
          if (
            error.response.data &&
            error.response.data.message &&
            error.response.data.message === "Parameter contains forbidden char"
          ) {
            setAlert(
              "Este parâmetro contém caracteres definidos como proibidos pela sua organização.",
              error.response.data.message,
              "error"
            );
            return;
          } else {
            setAlert(
              "Não foi possível criar o parâmetro",
              error.message,
              "error"
            );
            return;
          }
        });
    }

    /*
    if (value.includes("Criar")) {
      value = value.split("Criar ")[1];
    }
    */
    if (name === "create-template" || name === "withLinks") {
      value = event.target.checked;
    }
    if (name === "url_redirect") {
      value = value.replace("https://", "").replace("http://", "");
    }
    const populatedFields = {};
    if (item.populates) {
      const populated = item.populates.findIndex((item) => {
        return item[event.target.name] === event.target.value;
      });
      item.populate_fields.forEach((field) => {
        if (
          field === "utm_id" &&
          !props.data.popupItems.find((field) => field.label === "utm_id")
        ) {
          return;
        }
        populatedFields[field] = item.populates[populated]
          ? field === "utm_id"
            ? item.populates[populated][field]?.toLowerCase()
            : item.populates[populated][field]
          : "";
      });
    }

    if (item.populatesCustom) {
      const populated = item.populates.findIndex((item) => {
        return item[event.target.name] === event.target.value;
      });
      if (!item.populates[populated]) {
        setCustomParams([]);
      } else {
        let custom = [];
        let customParameters = props.data.popupItems.find(
          (item) => item.type === "custom_parameters"
        );

        if (customParameters) {
          customParameters = customParameters.fields.map((field) => field.name);
          customParameters.forEach((param) => {
            if (item.populates[populated][param]) {
              custom.push({
                name: param,
                parameter: item.populates[populated][param],
              });
            }
          });
        }

        setCustomParams(custom);
      }
    }
    if (event.target.type === "password") {
      if (
        document.getElementsByClassName("popup-password")[0].value ===
        document.getElementsByClassName("popup-password")[1].value
      ) {
        document
          .getElementsByClassName("popup-password")[1]
          .setCustomValidity("");
      } else {
        document
          .getElementsByClassName("popup-password")[1]
          .setCustomValidity("Passwords Don't Match");
      }
    }
    if (event.target.type === "date") {
      if (
        document.getElementsByClassName("popup-start-date")[0].value <=
        document.getElementsByClassName("popup-end-date")[0].value
      ) {
        document
          .getElementsByClassName("popup-end-date")[0]
          .setCustomValidity("");
      } else {
        document
          .getElementsByClassName("popup-end-date")[0]
          .setCustomValidity("Invalid Date");
      }
    }

    if (concatonatedParamType) {
      const valueToAdd = (value && { [name]: value }) || {};
      let currentVals = {
        ...concatonatedParamVals[concatonatedParamType],
        ...valueToAdd,
      };
      if (!value) {
        delete currentVals[name];
      }
      concatFields.forEach((field) => {
        if (field.name === name) {
          currentVals[name] = value;
        } else {
          currentVals[field.name] =
            (concatonatedParamVals &&
              concatonatedParamVals[concatonatedParamType] &&
              concatonatedParamVals[concatonatedParamType][field.name]) ||
            "";
        }
      });
      console.log(currentVals);
      valsChange({
        ...concatonatedParamVals,
        [concatonatedParamType]: currentVals,
      });

      name = `${concatonatedParamType}`;
      //  console.log(concatonatedSeparator);
      value = concatFields
        .map((field) => currentVals[field.name])
        .filter((val) => val)
        .join(concatonatedSeparator);
    }

    inputChange({
      ...formVals,
      [name]: value,
      ...populatedFields,
    });
  };

  const restoreFormValues = () => {
    if (!sessionItems && !sessionConcatItems && !sessionCustomItems) {
      return;
    }
    window.setTimeout(() => {
      inputChange(JSON.parse(sessionItems));
      valsChange(JSON.parse(sessionConcatItems));
      setCustomParams(JSON.parse(sessionCustomItems));
      window.sessionStorage.removeItem("formValues");
      window.sessionStorage.removeItem("concatValues");
      window.sessionStorage.removeItem("customValues");
    }, 300);
  };

  const getCustomParamsObject = (params) => {
    let custom = {};
    params.forEach((param) => {
      custom[param.name] = param.parameter;
    });
    return custom;
  };

  return (
    <div className="popup">
      <div className="popup-wrapper">
        <Translation>
          {(t) => (
            <React.Fragment>
              <div className="popup-header">
                <h2>{t(title)}</h2>

                <div className="popup-header-actions">
                  <div>
                    {window.location.pathname.includes(
                      "/links/active-links/create"
                    ) &&
                      process.env.REACT_APP_OPT_BULK_LINKS && (
                        <h5 className="popup-bulk-toggle">
                          {t(
                            "criar_e_gerir_links.create_popup.text.create_bulk"
                          )}
                          <Toggle
                            onChange={() => {
                              let storage = window.sessionStorage;
                              storage.setItem(
                                "formBulk",
                                JSON.stringify(formVals)
                              );
                              storage.setItem(
                                "concatBulk",
                                JSON.stringify(concatonatedParamVals)
                              );
                              storage.setItem(
                                "customBulk",
                                JSON.stringify(customParams)
                              );
                              props.goToBulk();
                            }}
                          />
                          {/*
                         <label className="switch">
                          <input
                            name="utm_content"
                            type="checkbox"
                            onChange={() => {
                              let storage = window.sessionStorage;
                              storage.setItem(
                                "formBulk",
                                JSON.stringify(formVals)
                              );
                              storage.setItem(
                                "concatBulk",
                                JSON.stringify(concatonatedParamVals)
                              );
                              storage.setItem(
                                "customBulk",
                                JSON.stringify(customParams)
                              );
                              props.goToBulk();
                            }}
                          />
                          <span className="slider round"></span>
                        </label>
                         */}
                        </h5>
                      )}
                  </div>
                  {window.location.pathname.includes(
                    "/links/active-links/create"
                  ) &&
                    process.env.REACT_APP_OPT_BULK_LINKS &&
                    !props.hasBulk && (
                      <div style={{ marginRight: "0.5em" }}>
                        <UpgradeRequired
                          plan="Professional"
                          admin={props.admin}
                          style={{ marginRight: "0.5em" }}
                          locked={[".popup-bulk-toggle"]}
                        />
                      </div>
                    )}
                  {props.data.closePopup && (
                    <Link to={returnURL}>
                      <Icon
                        icon={"close"}
                        onClick={() =>
                          props.cancelAction && props.cancelAction()
                        }
                      />
                    </Link>
                  )}
                </div>
              </div>
              <form
                action=""
                className="create-form"
                autoComplete="off"
                onSubmit={(event) => {
                  setLoading(true);
                  var submit = document.getElementById("outputValue")
                    ? props.data.onSubmit(event, {
                        ...formVals,
                        customParams: [...customParams],
                        full_url: document
                          .getElementById("outputValue")
                          .textContent.trim(),
                      })
                    : props.data.onSubmit(event, {
                        ...formVals,
                        customParams: [...customParams],
                        teamPermissions,
                      });
                  if (typeof submit === "string") {
                    // setError(submit);
                    // setLoading(false);
                  }
                }}
              >
                <div className="popup-form-items">
                  {step === 1 &&
                    props.data.popupItems.map((item) => (
                      <PopupContext.Provider
                        value={{
                          t,
                          item,
                          values: props.values,
                          formVals,
                          tempVals,
                          tempChange,
                          handleInputChange,
                          forbidden: props.forbidden,
                          customParams,
                          concatonatedParamVals,
                          inputChange,
                          setCustomParams,
                          users: props.users,
                          createParam: props.createParam,
                          valsChange,
                          teams: props.teams,
                          canCreate: props.canCreateParam,
                          manageParametersPermission:
                            props.manageParametersPermission,
                          manageTemplatesPermission:
                            props.manageTemplatesPermission,
                        }}
                      >
                        <React.Fragment>
                          {
                            <React.Fragment>
                              {(item.type === "select-multiple" && (
                                <SelectMultiple />
                              )) ||
                                (item.type === "select-normal" && (
                                  <SelectSingle />
                                )) ||
                                (item.type === "switch" && <SelectSwitch />) ||
                                (item.type === "select" && !item.visible && (
                                  <React.Fragment>
                                    <InputAuto />
                                    {(item.concatonatedParamType ===
                                      "utm_content" &&
                                      item.concatonatedParamsVisible &&
                                      item.concatonatedParamFields.map(
                                        (field, index) => (
                                          <InputAutoConcat field={field} />
                                        )
                                      )) ||
                                      (item.concatonatedParamType ===
                                        "utm_term" &&
                                        item.concatonatedParamsVisible &&
                                        item.concatonatedParamFields.map(
                                          (field, index) => (
                                            <InputAutoConcat field={field} />
                                          )
                                        ))}
                                    {item.hasConcatonatedParams &&
                                      !item.concatonatedParamsVisible && (
                                        <ConcatLink />
                                      )}
                                  </React.Fragment>
                                )) ||
                                (item.type === "input" && !item.visible && (
                                  <InputDefault />
                                )) ||
                                (item.type === "custom-param-add" && (
                                  <CustomUTMLink />
                                )) ||
                                (item.type === "campaign_parameters" &&
                                  item.fields.map((field) => (
                                    <InputCampaignParams field={field} />
                                  ))) ||
                                (item.type === "custom_parameters" &&
                                  item.fields.map((field) => (
                                    <InputCustomParams field={field} />
                                  ))) ||
                                (item.type === "checkbox" && (
                                  <InputCheckbox />
                                )) ||
                                (item.type === "password" && <Password />) ||
                                (item.type === "email" && <Email />) ||
                                (item.type === "textarea" && <Textarea />) ||
                                (item.type === "message" && <Message />) ||
                                (item.type === "date" && <DateInput />) ||
                                (item.type === "auth" &&
                                  formVals.shortener === "Bitly" && (
                                    <BitlyAuth />
                                  )) ||
                                (item.type === "auth" &&
                                  formVals.shortener === "tinyURL" && (
                                    <TinyURLAuth />
                                  )) ||
                                (item.type === "emailWarning" && (
                                  <EmailWarning
                                    users={props.users}
                                    org={props.org}
                                  />
                                ))}
                            </React.Fragment>
                          }
                        </React.Fragment>
                      </PopupContext.Provider>
                    ))}
                  {step === 2 &&
                    additionalStepItemData &&
                    additionalStepItemData.map((item) => (
                      <PopupContext.Provider
                        value={{
                          t,
                          item,
                          formVals,
                          values: props.values,
                          handleInputChange,
                          teamPermissions,
                          setTeamPermissions,
                        }}
                      >
                        <React.Fragment>
                          {item.type === "team-permissions" && (
                            <TeamPermissions />
                          )}
                        </React.Fragment>
                      </PopupContext.Provider>
                    ))}
                </div>
                {(!additionalSteps && (
                  <button
                    type="submit"
                    disabled={
                      loading ||
                      (required &&
                        !required.every((item) => {
                          return (
                            formVals[item] && formVals[item].toString().length
                          );
                        })) ||
                      (formVals.shortener === "Bitly" &&
                        !formVals.utm_campaign) ||
                      (formVals.teams && !formVals.teams.length) ||
                      /*    (props.values &&
                        Object.keys(formVals).every((key) => {
                          if (props.values[key]) {
                            return props.values[key] === formVals[key];
                          } else {
                            return true;
                          }
                        }) &&
                        customParams
                          .map((param) => param.name)
                          .every(
                            (param) =>
                              customParams.find(
                                (custom) => custom.name === param
                              ).parameter === props.values[`utm_${param}`]
                          )) */

                      (props.values &&
                        [...Object.entries(formVals)].every((val) => {
                          return props.values[val[0]] === val[1];
                        }) &&
                        customParams.every(
                          (custom) =>
                            props.values[`${custom.name}`] === custom.parameter
                        ))
                    }
                    className={`action-button no-margin popup-stretch ${
                      (formVals.shortener === "Bitly" &&
                        !formVals.utm_campaign &&
                        "display-none") ||
                      ""
                    }`}
                  >
                    {(!loading && t(submit)) || (
                      <PulseLoader
                        color={"#ffffff"}
                        loading={loading}
                        css={override}
                        size={10}
                      ></PulseLoader>
                    )}
                  </button>
                )) || (
                  <div className="popup-button-wrapper import">
                    <button
                      id="button-start"
                      className="action-button-2"
                      type="button"
                      disabled={step === 1}
                      onClick={() => {
                        setStep(step - 1);
                      }}
                    >
                      {t(
                        "importação_de_parâmetros_de_campanha.popup.button.back"
                      )}
                    </button>
                    {(step === additionalSteps + 1 && (
                      <button
                        id="button-end"
                        type="submit"
                        disabled={
                          loading ||
                          Object.values(teamPermissions).filter(
                            (val) => val !== null
                          ).length !== formVals.teams.length
                        }
                        className={`action-button no-margin popup-stretch ${
                          (formVals.shortener === "Bitly" &&
                            !formVals.utm_campaign &&
                            "display-none") ||
                          ""
                        }`}
                      >
                        {(!loading && t(submit)) || (
                          <PulseLoader
                            color={"#ffffff"}
                            loading={loading}
                            css={override}
                            size={10}
                          ></PulseLoader>
                        )}
                      </button>
                    )) || (
                      <div>
                        {(formVals.role !== "admin" &&
                          formVals.teams &&
                          formVals.teams.length && (
                            <button
                              className="action-button-2"
                              style={{ width: "100%" }}
                              type="button"
                              disabled={
                                step === additionalSteps + 1 ||
                                !formVals.teams ||
                                !formVals.username ||
                                !formVals.email ||
                                !formVals.role
                              }
                              onClick={() => {
                                setStep(step + 1);
                              }}
                            >
                              {t("login.login_form.button.next")}
                            </button>
                          )) || (
                          <button
                            id="button-end"
                            type="submit"
                            style={{ width: "100%" }}
                            disabled={
                              !formVals.username ||
                              !formVals.email ||
                              !formVals.role ||
                              loading ||
                              (props.values &&
                                Object.values(formVals).every((val) =>
                                  Object.values(props.values).some((val1) => {
                                    return val1 === val;
                                  })
                                ))
                            }
                            className={`action-button multistep`}
                          >
                            {(!loading && t(submit)) || (
                              <PulseLoader
                                color={"#ffffff"}
                                loading={loading}
                                css={override}
                                size={10}
                              ></PulseLoader>
                            )}
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
                {error && <h5 className="popup-submit-error">{error}</h5>}
                <ReactTooltip
                  className="tooltip-popup"
                  id="popup"
                  place={"right"}
                  type={"light"}
                  effect={"solid"}
                  multiline={true}
                  delayShow={100}
                  getContent={(dataTip) => {
                    let data = JSON.parse(dataTip);
                    if (!data) {
                      return;
                    }
                    return (
                      <div className="popup-tooltip">
                        <h3>{t(data.tooltipHeader) || ""}</h3>
                        <div>
                          {data.tooltipP1 && <h4>{t(data.tooltipP1) || ""}</h4>}
                          {data.tooltipP2 && (
                            <h4>{t(data.tooltipP2) || ""}.</h4>
                          )}
                          {data.listHeader && (
                            <h4>
                              <br></br>
                              {t(data.listHeader) || ""}
                            </h4>
                          )}
                          {data.listHeader && (
                            <ul>
                              {data.listItems.map((item) => (
                                <li>{t(item)}</li>
                              ))}
                            </ul>
                          )}
                          {data.tooltipP3 && (
                            <h4>
                              {" "}
                              <br></br>
                              {t(data.tooltipP3) || ""}
                            </h4>
                          )}
                          {data.tooltipStrong && (
                            <strong>{t(data.tooltipStrong)}</strong>
                          )}
                          {data.tooltipP4 && (
                            <h4>
                              {" "}
                              <br></br>
                              {t(data.tooltipP4) || ""}
                            </h4>
                          )}
                        </div>
                      </div>
                    );
                  }}
                ></ReactTooltip>
              </form>
              {hasOutput && (
                <div className="popup-output-url">
                  <h5>
                    {t(
                      "visualizar_links_todas_as_equipas.table.header_text.full_url"
                    )}
                  </h5>
                  <textarea
                    id="outputValue"
                    readOnly
                    name="outputValue"
                    value={linkBuilder(
                      formVals["http"],
                      formVals["url_redirect"],
                      {
                        utm_campaign: formVals["utm_campaign"],
                        utm_medium: formVals["utm_medium"],
                        utm_source: formVals["utm_source"],
                        utm_content: formVals["utm_content"],
                        utm_term: formVals["utm_term"],
                        utm_source_platform: formVals["utm_source_platform"],
                        utm_creative_format: formVals["utm_creative_format"],
                        utm_marketing_tactic: formVals["utm_marketing_tactic"],
                        utm_id: formVals["utm_id"],
                        utm_campaign_id: formVals["utm_campaign_id"],
                      },
                      { ...getCustomParamsObject(customParams) },
                      props.disableEncoding
                    )}
                    cols="30"
                    rows="4"
                    className="popup-textarea"
                  ></textarea>
                </div>
              )}
              {hasTaxonomy && (
                <div className="popup-output-url">
                  <h5>
                    {t(
                      "criar_e_gerir_campanhas.create_popup.text.preview_camp"
                    )}
                  </h5>
                  <textarea
                    id="outputValue"
                    readOnly
                    name="outputValue"
                    value={taxonomy
                      .map((tax) => {
                        let date =
                          (formVals.startDate &&
                            new Date(formVals.startDate)) ||
                          null;
                        let monthFull =
                          date &&
                          date.toLocaleString("default", {
                            month: "long",
                          });

                        switch (tax) {
                          case "aaaammdd":
                            return (
                              (!date && "[Ano Mês(MM) Dia]") ||
                              `${date.getFullYear()}${
                                (date.getMonth() < 9 &&
                                  "0" + (date.getMonth() + 1)) ||
                                date.getMonth() + 1
                              }${
                                (date.getDate() < 10 && "0" + date.getDate()) ||
                                date.getDate()
                              }`
                            );
                          case "aaaamm":
                            return (
                              (date &&
                                `${date.getFullYear()}${
                                  (date.getMonth() < 9 &&
                                    "0" + (date.getMonth() + 1)) ||
                                  date.getMonth() + 1
                                }`) ||
                              "[Ano Mês(MM)]"
                            );
                          case "aaaammm":
                            return (
                              (date &&
                                monthFull !== "Invalid Date" &&
                                `${date.getFullYear()}${monthFull
                                  .substring(0, 3)
                                  .toUpperCase()}`) ||
                              "[Ano Mês(Mmm)]"
                            );
                          case "aaaaao":
                            return (
                              (date && `${date.getFullYear()}ao`) || "[Ano ao]"
                            );
                          case "name":
                            return formVals.name_campaign || "[Nome Campanha]";
                          case "ao":
                            return "ao";
                          case "_":
                            return "_";
                          default:
                            return [":", "-"].includes(tax)
                              ? ` ${tax} `
                              : formVals[tax] ||
                                  [
                                    "{{",
                                    tax === "Nome da Campanha"
                                      ? t(
                                          "criar_e_gerir_parâmetros_de_campanha.campaign_parameters.field.ca_name"
                                        )
                                      : tax,
                                    "}}",
                                  ].join("");
                        }
                      })
                      .join("")}
                    cols="30"
                    rows={Math.ceil(taxonomy.length / 6)}
                    className="popup-textarea outputTax"
                  ></textarea>
                </div>
              )}
            </React.Fragment>
          )}
        </Translation>
      </div>
    </div>
  );
}

const SelectMultiple = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const { t, item, formVals, inputChange } = value;
        let itemObj = item.items.map((option) => {
          let dataObj = {
            label: option.team_name || "",
            value: option.id || "",
          };
          return dataObj;
        });
        return (
          <Translation>
            {(t) => (
              <div
                className={`${
                  (item.hasBorder && "popup-form-item-border") || ""
                }`}
              >
                <div className={`popup-form-item`}>
                  <label>{t(item.label)}</label>
                  <div className="popup-form-input-wrapper">
                    <TagPicker
                      data={itemObj}
                      disabled={formVals.role === "admin"}
                      placement="autoVerticalStart"
                      placeholder={t("popup.input.placeholder.select_ph")}
                      value={
                        (formVals.role === "admin" &&
                          itemObj.map((data) => data.value)) ||
                        formVals[item.name]
                      }
                      disabledItemValues={
                        (item.firstLocked && [formVals[item.name][0]]) || []
                      }
                      onChange={(inputVal) => {
                        if (inputVal === null) {
                          inputVal = [];
                        }
                        if (
                          item.firstLocked &&
                          !inputVal.includes(formVals[item.name][0])
                        ) {
                          inputVal = [formVals[item.name][0], ...inputVal];
                        }
                        inputChange(
                          { ...formVals, [item.name]: inputVal },
                          item
                        );
                      }}
                    />
                    {item.hasTooltip && (
                      <i
                        data-for="popup"
                        data-tip={JSON.stringify(item)}
                        className="material-icons gray-icon tooltip"
                      >
                        info
                      </i>
                    )}
                  </div>
                </div>
              </div>
            )}
          </Translation>
        );
      }}
    </PopupContext.Consumer>
  );
};

const SelectSingle = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const {
          t,
          item,
          values,
          formVals,
          tempVals,
          tempChange,
          handleInputChange,
        } = value;
        let itemObj = item.items.map((option) => {
          let dataObj = {
            label: option[item.name] || "",
            value: option[item.name] || "",
          };
          return dataObj;
        });
        if (
          !item.onlyAvailable &&
          formVals[item.name] &&
          !itemObj.find((obj) => obj.label === formVals[item.name])
        ) {
          itemObj.push({
            label: formVals[item.name],
            value: formVals[item.name],
          });
        }
        return (
          <Translation>
            {(t) => (
              <div
                className={`${
                  (item.hasBorder && "popup-form-item-border") || ""
                }`}
              >
                {(item.label !== "Group" ||
                  (item.label === "Group" && values.groups)) && (
                  <div className="popup-form-item">
                    <label>{t(item.label)}</label>
                    <div className="popup-form-input-wrapper">
                      <SelectPicker
                        data={itemObj}
                        placeholder={t("popup.input.placeholder.select_ph")}
                        appearance="default"
                        searchable={false}
                        cleanable={item.cleanable}
                        placement={
                          (item.name === "template" && "autoHorizontalStart") ||
                          "autoVerticalStart"
                        }
                        renderMenuItem={(label) => (
                          <div
                            onMouseEnter={() => {
                              let temp = {};
                              if (item.populates) {
                                let selected = item.items.find(
                                  (obj) => obj[item.name] === label
                                );

                                item.populate_fields.forEach((field) => {
                                  temp[field] = selected[field];
                                });

                                //console.log(temp);
                              }
                              if (item.populatesCustom) {
                                const populated = item.populates.findIndex(
                                  (obj) => {
                                    return obj.name === label;
                                  }
                                );
                                if (item.populates[populated]) {
                                  let values = Object.entries(
                                    item.populates[populated]
                                  );
                                  values
                                    .filter((val) => {
                                      if (
                                        val[0].includes("utm_") &&
                                        ![
                                          "utm_campaign",
                                          "utm_medium",
                                          "utm_source",
                                          "utm_content",
                                          "utm_term",
                                          "utm_source_platform",
                                          "utm_creative_format",
                                          "utm_marketing_tactic",
                                          "utm_id",
                                          "utm_campaign_id",
                                        ].includes(val[0])
                                      ) {
                                        return true;
                                      } else {
                                        return false;
                                      }
                                    })
                                    .forEach((val) => {
                                      temp[val[0]] = val[1];
                                    });
                                  tempChange(temp);
                                }
                              }
                            }}
                            onMouseLeave={() => tempChange({})}
                          >
                            {label}
                          </div>
                        )}
                        defaultValue={values && values[item.name]}
                        required={item.required}
                        value={formVals[item.name]}
                        style={{}}
                        onChange={(inputVal) =>
                          handleInputChange(
                            { target: { name: item.name, value: inputVal } },
                            item
                          )
                        }
                      />
                      {item.hasTooltip && (
                        <i
                          data-for="popup"
                          data-tip={JSON.stringify(item)}
                          className="material-icons gray-icon tooltip"
                        >
                          info
                        </i>
                      )}
                    </div>
                  </div>
                )}
              </div>
            )}
          </Translation>
        );
      }}
    </PopupContext.Consumer>
  );
};
const SelectSwitch = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const {
          t,
          item,
          values,
          formVals,
          tempVals,
          tempChange,
          handleInputChange,
        } = value;
        let itemObj = item.items.map((option) => {
          let dataObj = {
            label: option["label"] || "",
            value: option[item.name] || "",
          };
          return dataObj;
        });
        return (
          <div
            className={`${(item.hasBorder && "popup-form-item-border") || ""} `}
          >
            {(item.label !== "Group" ||
              (item.label === "Group" && values.groups)) && (
              <div className="popup-form-item switch-form-item">
                <div className="popup-switch-wrapper">
                  <label>{t(item.label)}</label>
                  <div className="popup-form-input-wrapper popup-switch">
                    <ButtonToolbar>
                      <ButtonGroup>
                        {itemObj.map((itemObj) => (
                          <Button
                            className={`${
                              (formVals[item.name] === itemObj.value &&
                                "selected-switch") ||
                              ""
                            }`}
                            onClick={() =>
                              handleInputChange(
                                {
                                  target: {
                                    name: item.name,
                                    value: itemObj.value,
                                  },
                                },
                                item
                              )
                            }
                          >
                            {itemObj.label}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </ButtonToolbar>

                    {item.hasTooltip && (
                      <i
                        data-for="popup"
                        data-tip={JSON.stringify(item)}
                        className="material-icons gray-icon tooltip"
                      >
                        info
                      </i>
                    )}
                  </div>
                </div>
                {formVals[item.name] && (
                  <h5 className="subtext">
                    {item.infoVals[formVals[item.name]]}
                  </h5>
                )}
              </div>
            )}
          </div>
        );
      }}
    </PopupContext.Consumer>
  );
};

const InputAuto = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const {
          t,
          item,
          values,
          formVals,
          tempVals,
          handleInputChange,
          forbidden,
          customParams,
          concatonatedParamVals,
          canCreate,
        } = value;
        let itemObj = item.items
          .filter((item, index, original) => {
            if (!item.parent) {
              return true;
            }
            let allValues = [];
            allValues.push(...Object.values(formVals));
            allValues.push(...customParams.map((param) => param.parameter));
            if (concatonatedParamVals.content) {
              allValues.push(...Object.values(concatonatedParamVals.content));
            }
            if (concatonatedParamVals.term) {
              allValues.push(...Object.values(concatonatedParamVals.term));
            }
            if (
              original.some((item) => {
                return item.parent.some((val) => {
                  return allValues.includes(val);
                });
              }) &&
              item.parent.some((val) => {
                return allValues.includes(val);
              })
            ) {
              return true;
            } else if (
              original.some((item) => {
                return item.parent.some((val) => {
                  return allValues.includes(val);
                });
              })
            ) {
              return false;
            } else {
              return true;
            }
          })
          .map((option) => {
            return { label: option.name, value: option.name };
          });
        if (
          formVals[item.name] &&
          !itemObj.find((obj) => obj.label === formVals[item.name])
        ) {
          itemObj.push({
            label: formVals[item.name],
            value: formVals[item.name],
          });
        }
        return (
          <Translation>
            {(t) => (
              <div
                className={`${
                  (item.hasBorder && "popup-form-item-border") || ""
                }`}
              >
                <div
                  className={`popup-form-item ${
                    (tempVals[item.name] && "temp-popup-value") || ""
                  }`}
                >
                  <label>{t(item.label)}</label>
                  <div className="popup-form-input-wrapper">
                    {(item.concatonatedParamsVisible && (
                      <Input
                        value={tempVals[item.name] || formVals[item.name] || ""}
                        disabled
                        placeholder={item.concatonatedParamFields
                          .map((param) => param.name)
                          .join(item.concatonatedSeparator)}
                      />
                    )) ||
                      (item.label === "utm_id" && (
                        <Input
                          value={
                            tempVals[item.name] || formVals[item.name] || ""
                          }
                          placeholder={t("popup.input.placeholder.select_ph")}
                          onChange={(inputVal) => {
                            if (
                              inputVal &&
                              forbidden.length &&
                              forbidden.some((forb) => inputVal.includes(forb))
                            ) {
                              setAlert(
                                "Este parâmetro contém caracteres definidos como proibidos pela sua organização. Por favor, escolha outro parâmetro.",
                                null,
                                "error"
                              );
                              return;
                            }
                            handleInputChange(
                              { target: { name: item.name, value: inputVal } },
                              item
                            );
                          }}
                        />
                      )) || (
                        <InputPicker
                          creatable={canCreate}
                          data={itemObj}
                          placeholder={t("popup.input.placeholder.select_ph")}
                          appearance="default"
                          defaultValue={values && values[item.name]}
                          required={item.required}
                          value={
                            tempVals[item.name] || formVals[item.name] || ""
                          }
                          onChange={(inputVal) => {
                            if (
                              inputVal &&
                              forbidden.length &&
                              forbidden.some((forb) => inputVal.includes(forb))
                            ) {
                              setAlert(
                                "Este parâmetro contém caracteres definidos como proibidos pela sua organização. Por favor, escolha outro parâmetro.",
                                null,
                                "error"
                              );
                              return;
                            }
                            handleInputChange(
                              { target: { name: item.name, value: inputVal } },
                              item,
                              null,
                              null,
                              true
                            );
                          }}
                          renderExtraFooter={() => (
                            <>
                              <Divider />
                              {itemObj.length !== item.items.length && (
                                <h5 className="drawer-label popup-dependency">
                                  {t(
                                    "criar_e_gerir_links.create_popup.dropdown_dependencies"
                                  )}
                                  <div>
                                    {[
                                      ...Object.values(formVals),
                                      ...customParams.map(
                                        (param) => param.parameter
                                      ),
                                    ]
                                      .filter((val) => {
                                        return item.items.some((item) =>
                                          item.parent.includes(val)
                                        );
                                      })
                                      .map((item) => (
                                        <Tag className="popup-dependency-tag">
                                          {item}
                                        </Tag>
                                      ))}
                                  </div>
                                </h5>
                              )}
                            </>
                          )}
                        />
                      )}
                  </div>
                </div>
              </div>
            )}
          </Translation>
        );
      }}
    </PopupContext.Consumer>
  );
};

const InputAutoConcat = (props) => {
  const field = props.field;
  //TODO Fix custom params disabling concat stuff
  return (
    <Translation>
      {(t) => (
        <PopupContext.Consumer>
          {(value) => {
            const {
              t,
              item,
              values,
              formVals,
              tempVals,
              handleInputChange,
              forbidden,
              customParams,
              concatonatedParamVals,
              valsChange,
              canCreate,
            } = value;

            let concatP = {};
            if (values && values[`${item.concatonatedParamType}`]) {
              item.concatonatedParamFields.forEach(
                (field, index) =>
                  (concatP[field.name] = values[
                    `${item.concatonatedParamType}`
                  ].split(item.concatonatedSeparator)[index])
              );
              if (
                !concatonatedParamVals[item.concatonatedParamType] ||
                !Object.values(
                  concatonatedParamVals[item.concatonatedParamType]
                ).length
              ) {
                valsChange({
                  ...concatonatedParamVals,
                  [item.concatonatedParamType]: concatP,
                });
              }
            }
            let itemObj = item.concatonatedItems
              .filter((option) => option.categoryId === field.categoryId)
              .filter((item, index, original) => {
                let allValues = [];
                allValues.push(...Object.values(formVals));
                allValues.push(...customParams.map((param) => param.parameter));
                if (concatonatedParamVals.content) {
                  allValues.push(
                    ...Object.values(concatonatedParamVals.content)
                  );
                }
                if (concatonatedParamVals.term) {
                  allValues.push(...Object.values(concatonatedParamVals.term));
                }
                if (
                  original.some((item) => {
                    return item.parent.some((val) => {
                      return allValues.includes(val);
                    });
                  }) &&
                  item.parent.some((val) => {
                    return allValues.includes(val);
                  })
                ) {
                  return true;
                } else if (
                  original.some((item) => {
                    return item.parent.some((val) => {
                      return allValues.includes(val);
                    });
                  })
                ) {
                  return false;
                } else {
                  return true;
                }
              })
              .map((option) => {
                return { label: option.name, value: option.name };
              });
            if (
              concatonatedParamVals &&
              concatonatedParamVals[field.name] &&
              !itemObj.find(
                (obj) => obj.label === concatonatedParamVals[field.name]
              )
            ) {
              itemObj.push({
                label: concatonatedParamVals[field.name],
                value: concatonatedParamVals[field.name],
              });
            }
            return (
              <div
                className={`popup-form-item concat-item ${
                  (tempVals[item.name] && "temp-popup-value") || ""
                }`}
              >
                <label>{t(field.name)}</label>
                <div className="popup-form-input-wrapper">
                  <InputPicker
                    creatable={canCreate}
                    data={itemObj}
                    placeholder={t("popup.input.placeholder.select_ph")}
                    appearance="default"
                    required={item.required}
                    value={
                      concatonatedParamVals[item.concatonatedParamType] &&
                      concatonatedParamVals[item.concatonatedParamType][
                        field.name
                      ]
                    }
                    onChange={(inputVal) => {
                      if (
                        inputVal &&
                        forbidden.length &&
                        forbidden.some((forb) => inputVal.includes(forb))
                      ) {
                        setAlert(
                          "Este parâmetro contém caracteres definidos como proibidos pela sua organização. Por favor, escolha outro parâmetro.",
                          null,
                          "error"
                        );
                        return;
                      }
                      handleInputChange(
                        { target: { name: field.name, value: inputVal } },
                        { ...field, items: item.concatonatedItems },
                        item.concatonatedParamType,
                        item.concatonatedSeparator,
                        true,
                        item.concatonatedParamFields
                      );
                    }}
                    renderExtraFooter={() => (
                      <>
                        <Divider />
                        {itemObj.length !==
                          item.concatonatedItems.filter(
                            (option) => option.categoryId === field.categoryId
                          ).length && (
                          <h5 className="drawer-label popup-dependency">
                            {t(
                              "criar_e_gerir_links.create_popup.dropdown_dependencies"
                            )}
                            <div>
                              {[
                                ...Object.values(formVals),
                                ...customParams.map((param) => param.parameter),
                              ]
                                .filter((val) => {
                                  return item.concatonatedItems
                                    .filter(
                                      (option) =>
                                        option.categoryId === field.categoryId
                                    )
                                    .some((item) => item.parent.includes(val));
                                })
                                .map((item) => (
                                  <Tag className="popup-dependency-tag">
                                    {item}
                                  </Tag>
                                ))}
                            </div>
                          </h5>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
            );
          }}
        </PopupContext.Consumer>
      )}
    </Translation>
  );
};

const ConcatLink = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const {
          item,
          formVals,
          customParams,
          concatonatedParamVals,
          manageParametersPermission,
          t,
        } = value;
        return (
          <React.Fragment>
            {manageParametersPermission && (
              <div className="concatonated-param-wrapper">
                <Link
                  className="popup-link"
                  onClick={() => {
                    let storage = window.sessionStorage;
                    storage.setItem("formValues", JSON.stringify(formVals));
                    storage.setItem(
                      "concatValues",
                      JSON.stringify(concatonatedParamVals)
                    );
                    storage.setItem(
                      "customValues",
                      JSON.stringify(customParams)
                    );

                    // console.log(storage);
                  }}
                  to={`/links/parameter-management?concatonate=${item.concatonatedParamType}`}
                >
                  <h5 className="concatonated-param-text">
                    {t(item.concatonatedParamText)}
                  </h5>
                </Link>
                <i
                  data-for="popup"
                  data-tip={JSON.stringify(item)}
                  className="material-icons gray-icon tooltip"
                >
                  info
                </i>
              </div>
            )}
          </React.Fragment>
        );
      }}
    </PopupContext.Consumer>
  );
};

const InputDefault = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const { t, item, values, formVals, tempVals, handleInputChange } =
          value;
        return (
          <div
            className={`popup-form-item ${
              (tempVals[item.name] && "temp-popup-value-input") || ""
            } ${(item.hasBorder && "popup-form-item-border") || ""}`}
          >
            <label htmlFor="link">{t(item.label)}</label>
            <div className="popup-form-input-wrapper">
              {item.hasHTTPSelect && (
                <SelectPicker
                  searchable={false}
                  cleanable={false}
                  data={[
                    { label: "https://", value: "https" },
                    { label: "http://", value: "http" },
                  ]}
                  value={
                    formVals["http"] ||
                    (values &&
                      values.full_url &&
                      values.full_url.includes("https") &&
                      "https") ||
                    (values &&
                      values.full_url &&
                      values.full_url.includes("http") &&
                      "http") ||
                    "https"
                  }
                  onChange={(inputVal) =>
                    handleInputChange(
                      { target: { name: "http", value: inputVal } },
                      item
                    )
                  }
                />
              )}
              <Input
                name="input"
                id={item.name}
                searchable={false}
                placeholder={t(item.placeholder)}
                required={item.required}
                value={tempVals[item.name] || formVals[item.name] || ""}
                onChange={(inputVal) =>
                  handleInputChange(
                    { target: { name: item.name, value: inputVal } },
                    item
                  )
                }
              />
            </div>
          </div>
        );
      }}
    </PopupContext.Consumer>
  );
};

const CustomUTMLink = () => {
  return (
    <Translation>
      {(t) => (
        <PopupContext.Consumer>
          {(value) => {
            const {
              item,
              formVals,
              customParams,
              concatonatedParamVals,
              manageParametersPermission,
            } = value;
            return (
              <React.Fragment>
                {manageParametersPermission && (
                  <div
                    className={` ${
                      (item.hasBorder && "popup-form-item-border") || ""
                    }`}
                  >
                    <div className="concatonated-param-wrapper">
                      <Link
                        className="popup-link"
                        onClick={() => {
                          let storage = window.sessionStorage;
                          storage.setItem(
                            "formValues",
                            JSON.stringify(formVals)
                          );
                          storage.setItem(
                            "concatValues",
                            JSON.stringify(concatonatedParamVals)
                          );
                          storage.setItem(
                            "customValues",
                            JSON.stringify(customParams)
                          );
                          // console.log(storage);
                        }}
                        to={`/links/parameter-management?concatonate=${item.concatonatedParamType}`}
                      >
                        <h5 className="concatonated-param-text">
                          {t(
                            "criar_e_gerir_links.create_popup.button.create_custom_parameters"
                          )}
                        </h5>
                      </Link>
                    </div>
                  </div>
                )}
              </React.Fragment>
            );
          }}
        </PopupContext.Consumer>
      )}
    </Translation>
  );
};

const InputCampaignParams = (props) => {
  const field = props.field;
  return (
    <Translation>
      {(t) => (
        <PopupContext.Consumer>
          {(value) => {
            const {
              t,
              item,
              formVals,
              handleInputChange,
              forbidden,
              values,
              canCreate,
            } = value;
            let itemObj = item.items
              .filter((option) => option.categoryId === field.categoryId)
              .filter((item, index, original) => {
                let allValues = [];
                allValues.push(...Object.values(formVals));
                if (
                  original.some((item) => {
                    return item.parent.some((val) => {
                      return allValues.includes(val);
                    });
                  }) &&
                  item.parent.some((val) => {
                    return allValues.includes(val);
                  })
                ) {
                  return true;
                } else if (
                  original.some((item) => {
                    return item.parent.some((val) => {
                      return allValues.includes(val);
                    });
                  })
                ) {
                  return false;
                } else {
                  return true;
                }
              })

              .map((option) => {
                return { label: option.name, value: option.name };
              });
            if (
              formVals[field.name] &&
              !itemObj.find((obj) => obj.label === formVals[field.name])
            ) {
              itemObj.push({
                label: formVals[field.name],
                value: formVals[field.name],
              });
            }
            return (
              <div
                className={`${
                  (item.hasBorder && "popup-form-item-border") || ""
                }`}
              >
                <div className={`popup-form-item`}>
                  <label>
                    {t(
                      field.name === "Nome da Campanha"
                        ? "criar_e_gerir_parâmetros_de_campanha.campaign_parameters.field.ca_name"
                        : field.name
                    )}
                  </label>
                  <div className="popup-form-input-wrapper">
                    <InputPicker
                      creatable={canCreate}
                      data={itemObj}
                      placeholder={t("popup.input.placeholder.select_ph")}
                      appearance="default"
                      defaultValue={values && values[field.name]}
                      required={true}
                      value={formVals[field.name]}
                      onChange={(inputVal) => {
                        if (
                          inputVal &&
                          forbidden.length &&
                          forbidden.some((forb) => inputVal.includes(forb))
                        ) {
                          setAlert(
                            "Este parâmetro contém caracteres definidos como proibidos pela sua organização. Por favor, escolha outro parâmetro.",
                            null,
                            "error"
                          );
                          return;
                        }
                        handleInputChange(
                          { target: { name: field.name, value: inputVal } },
                          { ...field, items: item.items },
                          null,
                          null,
                          true
                        );
                      }}
                      renderExtraFooter={() => (
                        <>
                          <Divider />
                          {itemObj.length !==
                            item.items.filter(
                              (option) => option.categoryId === field.categoryId
                            ).length && (
                            <h5 className="drawer-label popup-dependency">
                              {t(
                                "criar_e_gerir_links.create_popup.dropdown_dependencies"
                              )}
                              <div>
                                {[...Object.values(formVals)]
                                  .filter((val) => {
                                    return item.items
                                      .filter(
                                        (option) =>
                                          option.categoryId === field.categoryId
                                      )
                                      .some((item) =>
                                        item.parent.includes(val)
                                      );
                                  })
                                  .map((item) => (
                                    <Tag className="popup-dependency-tag">
                                      {item}
                                    </Tag>
                                  ))}
                              </div>
                            </h5>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </PopupContext.Consumer>
      )}
    </Translation>
  );
};

const InputCustomParams = (props) => {
  const field = props.field;
  return (
    <Translation>
      {(t) => (
        <PopupContext.Consumer>
          {(value) => {
            const {
              t,
              item,
              formVals,
              tempVals,
              forbidden,
              values,
              customParams,
              setCustomParams,
              concatonatedParamVals,
              createParam,
              canCreate,
            } = value;
            if (values && values[`${field.name}`]) {
              if (
                !customParams.map((item) => item.name).includes(`${field.name}`)
              ) {
                setCustomParams([
                  ...customParams,
                  { name: field.name, parameter: values[`${field.name}`] },
                ]);
              }
            }
            let itemObj = item.items
              .filter((option) => option.categoryId === field.categoryId)
              .filter((item, index, original) => {
                let allValues = [];
                allValues.push(...Object.values(formVals));
                allValues.push(...customParams.map((param) => param.parameter));
                if (concatonatedParamVals.content) {
                  allValues.push(
                    ...Object.values(concatonatedParamVals.content)
                  );
                }
                if (concatonatedParamVals.term) {
                  allValues.push(...Object.values(concatonatedParamVals.term));
                }
                if (
                  original.some((item) => {
                    return item.parent.some((val) => {
                      return allValues.includes(val);
                    });
                  }) &&
                  item.parent.some((val) => {
                    return allValues.includes(val);
                  })
                ) {
                  return true;
                } else if (
                  original.some((item) => {
                    return item.parent.some((val) => {
                      return allValues.includes(val);
                    });
                  })
                ) {
                  return false;
                } else {
                  return true;
                }
              })

              .map((option) => {
                return { label: option.name, value: option.name };
              });
            let param = customParams.find((param) => param.name === field.name);
            if (
              param &&
              param.parameter.length &&
              !itemObj.find((item) => item.label === param.parameter)
            ) {
              itemObj.push({
                label: param.parameter,
                value: param.parameter,
              });
            }
            return (
              <div
                className={`${
                  (item.hasBorder && "popup-form-item-border") || ""
                }`}
              >
                <div
                  className={`popup-form-item  ${
                    (tempVals[`${field.name}`] && "temp-popup-value") || ""
                  }`}
                >
                  <label>{t(field.name)}</label>
                  <div className="popup-form-input-wrapper">
                    <InputPicker
                      creatable={canCreate}
                      data={itemObj}
                      placeholder={t("popup.input.placeholder.select_ph")}
                      appearance="default"
                      defaultValue={values && values[field.name]}
                      required={field.required}
                      value={
                        tempVals[`${field.name}`] ||
                        (customParams.find(
                          (param) => param.name === field.name
                        ) &&
                          customParams.find(
                            (param) => param.name === field.name
                          ).parameter) ||
                        ""
                      }
                      onChange={async (inputVal) => {
                        if (inputVal === null) {
                          inputVal = "";
                        }
                        if (
                          inputVal &&
                          forbidden.length &&
                          forbidden.some((forb) => inputVal.includes(forb))
                        ) {
                          setAlert(
                            "Este parâmetro contém caracteres definidos como proibidos pela sua organização. Por favor, escolha outro parâmetro.",
                            null,
                            "error"
                          );
                          return;
                        }

                        if (inputVal && createParam) {
                          await createParam(inputVal, field.name).then(
                            (val) => val && (inputVal = val.name)
                          );
                        }
                        let params = JSON.parse(
                          JSON.stringify([...customParams])
                        );
                        let param = params.find((param) => {
                          return param.name === field.name;
                        });
                        if (param) {
                          param.parameter = inputVal;
                        } else {
                          params.push({
                            name: field.name,
                            parameter: inputVal,
                          });
                        }
                        setCustomParams(params);
                      }}
                      renderExtraFooter={() => (
                        <>
                          <Divider />
                          {itemObj.length !==
                            item.items.filter(
                              (option) => option.categoryId === field.categoryId
                            ).length && (
                            <h5 className="drawer-label popup-dependency">
                              {t(
                                "criar_e_gerir_links.create_popup.dropdown_dependencies"
                              )}
                              <div>
                                {[
                                  ...Object.values(formVals),
                                  ...customParams.map(
                                    (param) => param.parameter
                                  ),
                                ]
                                  .filter((val) => {
                                    return item.items
                                      .filter(
                                        (option) =>
                                          option.categoryId === field.categoryId
                                      )
                                      .some((item) =>
                                        item.parent.includes(val)
                                      );
                                  })
                                  .map((item) => (
                                    <Tag className="popup-dependency-tag">
                                      {item}
                                    </Tag>
                                  ))}
                              </div>
                            </h5>
                          )}
                        </>
                      )}
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </PopupContext.Consumer>
      )}
    </Translation>
  );
};

const InputCheckbox = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const {
          t,
          item,
          formVals,
          handleInputChange,
          manageTemplatesPermission,
        } = value;
        return (
          <React.Fragment>
            {manageTemplatesPermission && (
              <React.Fragment>
                <div className="popup-form-item-border">
                  <div className="concatonated-param-wrapper ">
                    <h5 className="concatonated-param-text margin-right no-pointer popup-link">
                      <Checkbox
                        onChange={(inputVal, checked) =>
                          handleInputChange(
                            { target: { name: item.name, checked: checked } },
                            item
                          )
                        }
                      >
                        {t(item.label)}
                      </Checkbox>
                    </h5>
                  </div>
                </div>
                {formVals[item.name] && item.name === "create-template" && (
                  <div
                    className={`popup-form-item ${
                      (item.hasBorder && "popup-form-item-border") || ""
                    }`}
                  >
                    <label htmlFor="link">
                      {t("criar_e_gerir_templates.table.header_text.name") +
                        " " +
                        t("menu.barra_esquerda.level_2.template")}
                    </label>
                    <div className="popup-form-input-wrapper">
                      <Input
                        searchable={false}
                        autoFocus={true}
                        required={item.required}
                        value={formVals["template-name"]}
                        onChange={(inputVal) =>
                          handleInputChange(
                            {
                              target: {
                                name: "template-name",
                                value: inputVal,
                              },
                            },
                            item
                          )
                        }
                      />

                      {item.hasTooltip && (
                        <i
                          data-for="popup"
                          data-tip={JSON.stringify(item)}
                          className="material-icons gray-icon tooltip"
                        >
                          info
                        </i>
                      )}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        );
      }}
    </PopupContext.Consumer>
  );
};

const Password = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const { t, item, formVals, handleInputChange, values } = value;
        return (
          <>
            <div
              className={`popup-form-item ${
                (item.hasBorder && "popup-form-item-border") || ""
              }`}
            >
              <label htmlFor="link">{t(item.label)}</label>
              <input
                className="popup-password"
                type="password"
                pattern={passwordRegex}
                required={item.required}
                name={item.name}
                minLength={8}
                value={formVals[item.name]}
                defaultValue={values && values[item.name]}
                onChange={(event) => handleInputChange(event, item)}
              />
            </div>
            {item.subtext && (
              <h6 className="popup-form-item subtext subtext-sm">
                {t("login.login_form.password_requirements")}
              </h6>
            )}
          </>
        );
      }}
    </PopupContext.Consumer>
  );
};

const Email = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const { t, item, formVals, handleInputChange, values } = value;
        return (
          <div
            className={`popup-form-item ${
              (item.hasBorder && "popup-form-item-border") || ""
            }`}
          >
            <label htmlFor="link">{t(item.label)}</label>
            <div className="popup-form-input-wrapper">
              <input
                type="email"
                name={item.name}
                value={formVals[item.name]}
                defaultValue={values && values[item.name]}
                onChange={(event) => handleInputChange(event, item)}
              />
            </div>
          </div>
        );
      }}
    </PopupContext.Consumer>
  );
};

const Textarea = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const { t, item, formVals, handleInputChange } = value;
        return (
          <div
            className={`popup-form-item ${
              (item.hasBorder && "popup-form-item-border") || ""
            }`}
          >
            <label htmlFor="mensagem">{t(item.label)}</label>
            <div className="popup-form-input-wrapper">
              <Input
                searchable={false}
                componentClass="textarea"
                rows={5}
                required={item.required}
                value={formVals[item.name]}
                onChange={(inputVal) =>
                  handleInputChange(
                    { target: { name: item.name, value: inputVal } },
                    item
                  )
                }
              />
            </div>
          </div>
        );
      }}
    </PopupContext.Consumer>
  );
};

const Message = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const { t, item, formVals, concatonatedParamVals, customParams } =
          value;
        return (
          <div
            className={`popup-form-item-column ${
              (item.hasBorder && "popup-form-item-border") || ""
            }`}
          >
            <h5>{t(item.messageHeader)}</h5>
            <div className="popup-message-margin">
              <h4>{t(item.messageStatus)}</h4>
              {item.messageTaxonomy && (
                <h4>
                  <strong>{item.messageTaxonomy}</strong>
                </h4>
              )}
            </div>
            {item.messageOption && (
              <div className="popup-message-option">
                <h4>{t(item.messageOption)}</h4>
                <Link
                  className="popup-link"
                  onClick={() => {
                    let storage = window.sessionStorage;
                    storage.setItem("formValues", JSON.stringify(formVals));
                    storage.setItem(
                      "concatValues",
                      JSON.stringify(concatonatedParamVals)
                    );
                    storage.setItem(
                      "customValues",
                      JSON.stringify(customParams)
                    );
                  }}
                  to={item.messageLink}
                >
                  {item.messageButton && (
                    <button type="button" className="action-button-color-3">
                      {t(item.messageButton)}
                    </button>
                  )}
                </Link>
              </div>
            )}
          </div>
        );
      }}
    </PopupContext.Consumer>
  );
};

const DateInput = () => {
  return (
    <Translation>
      {(t) => (
        <PopupContext.Consumer>
          {(value) => {
            const { item, formVals, handleInputChange } = value;
            return (
              <React.Fragment>
                <div
                  className={`${(item.hasBorder && "popup-form-item") || ""}`}
                >
                  <label>
                    {t("criar_e_gerir_campanhas.create_popup.field.dates")}
                  </label>
                  <div className={`popup-form-input-wrapper`}>
                    <InputGroup style={{ width: 460 }}>
                      <InputGroup.Addon>
                        {t("criar_e_gerir_campanhas.create_popup.field.start") +
                          ":"}
                      </InputGroup.Addon>
                      <DatePicker
                        format="YYYY-MM-DD"
                        placeholder={t(
                          "criar_e_gerir_campanhas.create_popup.placeholder.aaaammdd"
                        )}
                        placement="autoVerticalStart"
                        appearance="subtle"
                        value={formVals["startDate"]}
                        disabledDate={(date) =>
                          formVals["endDate"] &&
                          date > new Date(formVals["endDate"])
                        }
                        onChange={(inputVal) =>
                          handleInputChange(
                            { target: { name: "startDate", value: inputVal } },
                            item
                          )
                        }
                      />
                      <InputGroup.Addon>
                        {t("criar_e_gerir_campanhas.create_popup.field.end") +
                          ":"}
                      </InputGroup.Addon>
                      <DatePicker
                        format="YYYY-MM-DD"
                        placeholder={t(
                          "criar_e_gerir_campanhas.create_popup.placeholder.aaaammdd"
                        )}
                        placement="autoVerticalStart"
                        appearance="subtle"
                        value={formVals["endDate"]}
                        disabledDate={(date) =>
                          formVals["startDate"] &&
                          date < new Date(formVals["startDate"])
                        }
                        onChange={(inputVal) =>
                          handleInputChange(
                            { target: { name: "endDate", value: inputVal } },
                            item
                          )
                        }
                      />
                    </InputGroup>
                  </div>
                </div>
                {item.subtext && (
                  <h6 className="popup-form-item subtext subtext-sm">
                    {t(
                      "criar_e_gerir_campanhas.create_popup.info.timestamp_info"
                    )}
                  </h6>
                )}
              </React.Fragment>
            );
          }}
        </PopupContext.Consumer>
      )}
    </Translation>
  );
};

const BitlyAuth = () => {
  return (
    <Translation>
      {(t) => (
        <PopupContext.Consumer>
          {(value) => {
            const { t, item, formVals } = value;
            return (
              <div
                className={`popup-form-item ${
                  (item.hasBorder && "popup-form-item-border") || ""
                }`}
              >
                <a
                  className="action-button auth-button no-margin"
                  href={`${`https://bitly.com/oauth/authorize?client_id=${process.env.REACT_APP_BITLY_CLIENT_ID}&redirect_uri=https://url-builder-dt-live.herokuapp.com/links/link-shorteners/auth/bitly&state=${formVals.teams}`}`}
                >
                  {t("AUTENTICAR COM BITLY")}
                </a>
              </div>
            );
          }}
        </PopupContext.Consumer>
      )}
    </Translation>
  );
};

const TinyURLAuth = () => {
  return (
    <PopupContext.Consumer>
      {(value) => {
        const { item, formVals, handleInputChange, values } = value;
        return (
          <div
            className={`popup-form-item ${
              (item.hasBorder && "popup-form-item-border") || ""
            }`}
          >
            <label htmlFor="link">{"Token"}</label>
            <div className="popup-form-input-wrapper">
              <input
                type="text"
                name={item.name}
                value={formVals[item.name]}
                required
                defaultValue={values && values[item.name]}
                onChange={(event) => handleInputChange(event, item)}
              />
            </div>
          </div>
        );
      }}
    </PopupContext.Consumer>
  );
};

const EmailWarning = (props) => {
  const users = props.users;
  const org = props.org;
  return (
    <Translation>
      {(t) => (
        <PopupContext.Consumer>
          {(value) => {
            const {
              t,
              item,
              formVals,
              handleInputChange,
              values,
              inputChange,
              teams,
            } = value;
            let itemObj = users.map((user) => {
              return {
                label: user.userHandle,
                value: user.email,
                role:
                  (user.orgs[0].role === "admin" &&
                    "organização.setting.text.admins") ||
                  "menu.barra_esquerda.level_2.users",
              };
            });
            return (
              <div>
                <div className="popup-form-item popup-center">
                  <h4>
                    {t(
                      "criar_e_gerir_campanhas.email_popup.text.ca_email_text_1"
                    )}{" "}
                    <strong>{values.utm_campaign}</strong>,{" "}
                    {t(
                      "criar_e_gerir_campanhas.email_popup.text.ca_email_text_2"
                    )}{" "}
                    <strong>
                      {org} [
                      {values.teams
                        .filter((team) =>
                          teams.find((allTeams) => allTeams.id === team)
                        )
                        .map(
                          (team) =>
                            teams.find((allTeams) => allTeams.id === team)
                              .team_name
                        )
                        .join(" , ")}
                      ]
                    </strong>
                    ,{" "}
                    {t(
                      "criar_e_gerir_campanhas.email_popup.text.ca_email_text_3"
                    )}{" "}
                    <strong>{values.startDate}</strong>{" "}
                    {t(
                      "criar_e_gerir_campanhas.email_popup.text.ca_email_text_4"
                    )}{" "}
                    <strong>{values.endDate}</strong>.
                  </h4>
                </div>
                <div className={`popup-form-item`}>
                  <label htmlFor="mensagem">
                    {t(
                      "criar_e_gerir_campanhas.email_popup.field.instructions"
                    )}
                  </label>
                  <div className="popup-form-input-wrapper">
                    <Input
                      searchable={false}
                      componentClass="textarea"
                      placeholder={t(
                        "criar_e_gerir_campanhas.email_popup.placeholder.input_message"
                      )}
                      rows={5}
                      required={item.required}
                      value={formVals["message"]}
                      onChange={(inputVal) =>
                        handleInputChange(
                          { target: { name: "message", value: inputVal } },
                          item
                        )
                      }
                    />
                  </div>
                </div>
                <div className={`popup-form-item`}>
                  <label htmlFor="mensagem">
                    {t(
                      "criar_e_gerir_campanhas.email_popup.field.users_active"
                    )}
                  </label>
                  <div className="popup-form-input-wrapper">
                    <TagPicker
                      data={itemObj}
                      placement="autoVerticalStart"
                      placeholder={t("popup.input.placeholder.select_ph")}
                      groupBy="role"
                      renderMenuGroup={(label, item) => {
                        return (
                          <div className="group-input">
                            <i className="material-icons gray-icon icon-margin-left">
                              {(label === "Administradores" &&
                                "admin_panel_settings") ||
                                "person"}
                            </i>{" "}
                            {t(label)} - ({item.children.length})
                          </div>
                        );
                      }}
                      renderExtraFooter={() => (
                        <Checkbox
                          className="check-selector-footer"
                          onChange={(val, checked) => {
                            handleInputChange(
                              {
                                target: {
                                  name: "users",
                                  value:
                                    (checked &&
                                      users.map((user) => user.email)) ||
                                    [],
                                },
                              },
                              item
                            );
                          }}
                          checked={
                            formVals["users"] &&
                            formVals["users"].length === users.length
                          }
                        >
                          {t(
                            "gerir_dependências_de_parâmetros_utm.param_dependency.checkbox.select_all"
                          )}
                        </Checkbox>
                      )}
                      value={formVals["users"]}
                      onChange={(inputVal) =>
                        inputChange({ ...formVals, users: inputVal }, item)
                      }
                    />
                  </div>
                </div>
              </div>
            );
          }}
        </PopupContext.Consumer>
      )}
    </Translation>
  );
};

/*Deprecated*/
const TeamPermissions = () => {
  useEffect(() => {
    ReactTooltip.rebuild();
  });

  return (
    <Translation>
      {(t) => (
        <PopupContext.Consumer>
          {(value) => {
            const { formVals, teamPermissions, setTeamPermissions, item } =
              value;
            return (
              <React.Fragment>
                {formVals.teams &&
                  formVals.teams.map((team) => (
                    <div className={`popup-form-item`}>
                      <label htmlFor="">
                        {
                          item.items.find((allTeams) => allTeams.id === team)
                            .team_name
                        }
                      </label>
                      <SelectPicker
                        data={[
                          {
                            label: t("dashboard.user_grid.header_text.viewer"),
                            value: "viewer",
                            icon: "visibility",
                            tooltip: {
                              tooltipHeader: "Permissões Viewer",
                              tooltipP1:
                                "Utilizadores com role Viewer numa equipa podem apenas visualizar conteúdos como campanhas, links e parâmetros. ",
                              listHeader: t(
                                "dashboard.user_grid.header_text.permissions"
                              ),
                              listItems: [
                                t(
                                  "criar_e_gerir_utilizadores.permissions_table.text.view_camps"
                                ),
                              ],
                            },
                          },
                          {
                            label: t(
                              "criar_e_gerir_utilizadores.invite_popup.value.member"
                            ),
                            value: "member",
                            icon: "person",
                            tooltip: {
                              tooltipHeader: "Permissões Viewer",
                              tooltipP1:
                                "Utilizadores com role Viewer numa equipa podem apenas visualizar conteúdos como campanhas, links e parâmetros. ",
                              listHeader: t(
                                "dashboard.user_grid.header_text.permissions"
                              ),
                              listItems: [
                                t(
                                  "criar_e_gerir_utilizadores.permissions_table.text.view_camps"
                                ),
                              ],
                            },
                          },
                          {
                            label: t("dashboard.user_grid.header_text.leader"),
                            value: "leader",
                            icon: "supervisor_account",
                            tooltip: {
                              tooltipHeader: "Permissões Viewer",
                              tooltipP1:
                                "Utilizadores com role Viewer numa equipa podem apenas visualizar conteúdos como campanhas, links e parâmetros. ",
                              listHeader: t(
                                "dashboard.user_grid.header_text.permissions"
                              ),
                              listItems: [
                                t(
                                  "criar_e_gerir_utilizadores.permissions_table.text.view_camps"
                                ),
                              ],
                            },
                          },
                        ]}
                        required
                        placeholder={t("popup.input.placeholder.select_ph")}
                        appearance="default"
                        searchable={false}
                        value={teamPermissions[team]}
                        style={{}}
                        onChange={(inputVal) =>
                          setTeamPermissions({
                            ...teamPermissions,
                            [team]: inputVal,
                          })
                        }
                        renderMenuItem={(label, menuItem) => (
                          <div className="team-permissions-item">
                            <div>
                              <i className="material-icons gray-icon info-icon">
                                {menuItem.icon}
                              </i>
                              <span>{menuItem.label}</span>
                            </div>
                          </div>
                        )}
                      />
                      <ReactTooltip
                        className="tooltip-popup"
                        id="popup-permissions"
                        place={"right"}
                        type={"light"}
                        effect={"solid"}
                        multiline={true}
                        delayShow={100}
                        getContent={(dataTip) => {
                          let data = JSON.parse(dataTip);
                          if (!data) {
                            return;
                          }
                          return (
                            <div className="popup-tooltip">
                              <h3>{t(data.tooltipHeader) || ""}</h3>
                              <div>
                                {data.tooltipP1 && (
                                  <h4>{t(data.tooltipP1) || ""}</h4>
                                )}
                                {data.tooltipP2 && (
                                  <h4>{t(data.tooltipP2) || ""}.</h4>
                                )}
                                {data.listHeader && (
                                  <h4>
                                    <br></br>
                                    {t(data.listHeader) || ""}
                                  </h4>
                                )}
                                {data.listHeader && (
                                  <ul>
                                    {data.listItems.map((item) => (
                                      <li>{t(item)}</li>
                                    ))}
                                  </ul>
                                )}
                              </div>
                            </div>
                          );
                        }}
                      />
                    </div>
                  ))}
              </React.Fragment>
            );
          }}
        </PopupContext.Consumer>
      )}
    </Translation>
  );
};

export default Popup;

import React, { Suspense } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Popup from "../components/Popup";
import ProtectedRoute from "../components/ProtectedRoute";
import setAlert from "../helpers/notifications/alerts";
import setNotification from "../helpers/notifications/notifications";
import planManager from "../helpers/organizationPlanData";
import permissionsManager from "../helpers/permissionsManager";
///Campaigns
const ActiveCampaigns = React.lazy(() =>
  import("../views/Campaigns/ActiveCampaigns/index")
);
const CampaignParameterManagementView = React.lazy(() =>
  import("../views/Campaigns/CampaignParameterManagementView/index")
);
const CampaignTaxonomyView = React.lazy(() =>
  import("../views/Campaigns/CampaignTaxonomyView/index")
);
const CampaignParameterDependenciesNew = React.lazy(() =>
  import("../views/Campaigns/CampaignParameterDependenciesManagementNew/index")
);
const CampaignParameterDependencyVisualizerView = React.lazy(() =>
  import("../views/Campaigns/CampaignParameterDependencyVisualizerView/index")
);
const CampaignParametersView = React.lazy(() =>
  import("../views/Campaigns/CampaignParametersView/index")
);
const ArchivedCampaigns = React.lazy(() =>
  import("../views/Campaigns/ArchivedCampaigns/index")
);

const RoutingCampaigns = (props) => {
  return (
    <>
      <ProtectedRoute
        path={[
          "/campaigns/active-campaigns",
          "/campaigns/active-campaigns/create",
          "/campaigns/active-campaigns/email",
          "/campaigns/active-campaigns/edit",
          "/campaigns/active-campaigns/duplicate",
          "/campaigns/active-campaigns/delete",
          "/campaigns/active-campaigns/archive",
        ]}
        protectedPaths={[
          "/campaigns/active-campaigns/create",
          "/campaigns/active-campaigns/email",
          "/campaigns/active-campaigns/edit",
          "/campaigns/active-campaigns/duplicate",
          "/campaigns/active-campaigns/delete",
          "/campaigns/active-campaigns/archive",
        ]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager("team", props.teamRole, "manageCampaigns")
        }
        redirect="/error/403"
        exact
        render={(props2) => (
          <ActiveCampaigns
            user={props.user}
            teams={props.teams}
            organization={props.organization}
            setNotification={setNotification}
            setError={setAlert}
            teamRole={props.teamRole}
            teamSelected={props.teamSelected}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/campaigns/archive",
          "/campaigns/archive/activate",
          "/campaigns/archive/delete",
          "/campaigns/archive/clone",
          "/campaigns/archive/clone-link",
        ]}
        protectedPaths={[
          "/campaigns/archive/activate",
          "/campaigns/archive/delete",
          "/campaigns/archive/clone",
          "/campaigns/archive/clone-link",
        ]}
        redirect="/error/403"
        authorized={
          props.user.orgAdmin ||
          permissionsManager("team", props.teamRole, "viewArchivedCampaigns")
        }
        exact
        render={() => (
          <ArchivedCampaigns
            user={props.user}
            teams={props.teams}
            organization={props.organization}
            setError={setAlert}
            teamSelected={props.teamSelected}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/campaigns/parameter-management/",
          "/campaigns/parameter-management/confirm",
        ]}
        protectedPaths={[
          "/campaigns/parameter-management/",
          "/campaigns/parameter-management/confirm",
        ]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager(
            "org",
            props.user.orgAdmin,
            "manageCampaignParameters"
          )
        }
        redirect="/error/403"
        exact
        render={() => (
          <CampaignParameterManagementView
            setError={setAlert}
            setNotification={setNotification}
            user={props.user}
            teams={props.teams}
            updateTeams={props.updateTeams}
            teamSelected={props.teamSelected}
            organization={props.organization}
            teamRole={props.teamRole}
            plan={props.plan}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/campaigns/parameter-management/",
          "/campaigns/parameter-management/confirm",
        ]}
        protectedPaths={[
          "/campaigns/parameter-management/",
          "/campaigns/parameter-management/confirm",
        ]}
        authorized={planManager(props.plan, "campaign_parameters")}
        redirect="/error/426"
        exact
      />
      <ProtectedRoute
        path={[
          "/campaigns/campaign-taxonomy",
          "/campaigns/campaign-taxonomy/confirm",
        ]}
        protectedPaths={[
          "/campaigns/campaign-taxonomy",
          "/campaigns/campaign-taxonomy/confirm",
        ]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager(
            "org",
            props.user.orgAdmin,
            "manageCampaignTaxonomy"
          )
        }
        redirect="/error/403"
        exact
        render={() => (
          <CampaignTaxonomyView
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            updateTeams={props.updateTeams}
            teamSelected={props.teamSelected}
            organization={props.organization}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/campaigns/campaign-taxonomy",
          "/campaigns/campaign-taxonomy/confirm",
        ]}
        protectedPaths={[
          "/campaigns/campaign-taxonomy",
          "/campaigns/campaign-taxonomy/confirm",
        ]}
        authorized={planManager(props.plan, "campaign_taxonomy")}
        redirect="/error/426"
        exact
      />
      <ProtectedRoute
        path={[
          "/campaigns/parameters",
          "/campaigns/parameters/delete",
          "/campaigns/parameters/import/csv",
        ]}
        protectedPaths={["/campaigns/parameters/delete"]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager(
            "org",
            props.user.orgAdmin,
            "manageCampaignParameters"
          )
        }
        redirect="/error/403"
        exact
        render={() => (
          <CampaignParametersView
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            updateTeams={props.updateTeams}
            organization={props.organization}
            teamSelected={props.teamSelected}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/campaigns/parameters",
          "/campaigns/parameters/delete",
          "/campaigns/parameters/import/csv",
        ]}
        protectedPaths={["/campaigns/parameters/delete"]}
        authorized={planManager(props.plan, "campaign_parameters")}
        redirect="/error/426"
        exact
      />
      <ProtectedRoute
        path="/campaigns/parameters/import/csv"
        protectedPaths={["/campaigns/parameters/import/csv"]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager(
            "org",
            props.user.orgAdmin,
            "importCampaignParameters"
          )
        }
        redirect="/error/403"
        exact
      />
      <ProtectedRoute
        path="/campaigns/parameters/import/csv"
        protectedPaths={["/campaigns/parameters/import/csv"]}
        authorized={planManager(props.plan, "campaign_parameters")}
        redirect="/error/426"
        exact
      />
      <ProtectedRoute
        path={[
          "/campaigns/dependencies-management",
          "/campaigns/dependencies-management/confirm",
        ]}
        protectedPaths={[
          "/campaigns/dependencies-management",
          "/campaigns/dependencies-management/confirm",
        ]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager(
            "org",
            props.user.orgAdmin,
            "manageCampaignParameters"
          )
        }
        redirect="/error/403"
        exact
        render={() => (
          <CampaignParameterDependenciesNew
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            updateTeams={props.updateTeams}
            organization={props.organization}
            teamRole={props.teamRole}
            teamSelected={props.teamSelected}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/campaigns/dependencies-management",
          "/campaigns/dependencies-management/confirm",
        ]}
        protectedPaths={[
          "/campaigns/dependencies-management",
          "/campaigns/dependencies-management/confirm",
        ]}
        authorized={planManager(props.plan, "parameter_dependencies")}
        redirect="/error/426"
        exact
      />
      <ProtectedRoute
        path="/campaigns/dependency-mapping"
        protectedPaths={["/campaigns/dependency-mapping"]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager(
            "org",
            props.user.orgAdmin,
            "manageCampaignParameters"
          )
        }
        redirect="/error/403"
        exact
        render={() => (
          <CampaignParameterDependencyVisualizerView
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            teamSelected={props.teamSelected}
            updateTeams={props.updateTeams}
            teamRole={props.teamRole}
            organization={props.organization}
          />
        )}
      />
      <ProtectedRoute
        path="/campaigns/dependency-mapping"
        protectedPaths={["/campaigns/dependency-mapping"]}
        authorized={planManager(props.plan, "parameter_dependencies")}
        redirect="/error/426"
        exact
      />
    </>
  );
};

export default RoutingCampaigns;

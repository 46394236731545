import { getUserTeamsData } from "../../services/teams";
import { setDebugNotification } from "../notifications/debug";

//? [LEGACY] Function that updates the legacy parameters to the new format
const legacyParamsUpdate = (parameters) => {
  //Define the legacy parameters
  const legacyParams = [
    "Campaign",
    "Medium",
    "Source",
    "Content",
    "Term",
    "Source Platform",
    "Creative Format",
    "Marketing Tactic",
    "Campaign Id",
    "Id",
  ];
  //Iterate through the parameters array and update the legacy parameters
  parameters.forEach((parameter) => {
    //Update the parameter name
    if (legacyParams.includes(parameter.name)) {
      parameter.name =
        "utm_" + parameter.name.toLowerCase().replace(/\ /g, "_");
    }
    //Update the parameter dependency
    if (legacyParams.includes(parameter.dependency)) {
      parameter.dependency =
        "utm_" + parameter.dependency.toLowerCase().replace(/\ /g, "_");
    }
  });
  return parameters;
};

const getTeamsInformation = (authToken, user, organization) => {
  return getUserTeamsData(authToken, organization.id)
    .then((response) => {
      //In case of an empty response, return a rejected promise
      if (!response) return Promise.reject("No data retrieved");

      //Initialize the main team object
      let baseTeam = {
        concat_separator: "_",
        status: "active",
        concatonated: [],
        campaign_taxonomy: false,
        team_taxonomy: [],
        parameters: [],
        campaign_parameters: [],
      };

      let teams = [];

      response
        .filter((team) => team.status === "active")
        .forEach((team) => {
          team = { ...baseTeam, ...team };
          //Set the team ID to the response teamId
          team.id = team.teamId;
          team.team_name = team.name;

          //Iterate through the organization's link shorteners and add them to the team's shorteners array if the team id is included in the shortener's teams array
          team.shorteners = [];
          organization.link_shorteners.forEach((shortener) => {
            if (shortener.teams.includes(team.teamId)) {
              team.shorteners.push(shortener);
            }
          });

          //? [LEGACY] Update the legacy parameters to the new format
          team.parameters = legacyParamsUpdate(team.parameters);

          //Add the team to the user teams array
          teams.push(team);
        });

      return Promise.resolve(teams);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject("Error getting user teams information");
    });
};

export { getTeamsInformation };

import React, { Suspense, useState } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import Popup from "../components/Popup";
import resetAuthData from "../helpers/authDataReset";
import { confirmAccount, recoverPassword } from "../helpers/dataFunctions/user";
import setAlert from "../helpers/notifications/alerts";
import { mainPopupData } from "../helpers/popupData";
import i18n from "../i18n";
import { confirmRecovery, confirmUser } from "../services/user";
///Main
const MainView = React.lazy(() => import("../views/Main"));
const TermsConditionsView = React.lazy(() =>
  import("../views/Misc/Legal/TermsConditionsView")
);
const CookiePolicyView = React.lazy(() =>
  import("../views/Misc/Legal/CookiePolicyView")
);
const PrivacyPolicyView = React.lazy(() =>
  import("../views/Misc/Legal/PrivacyPolicyView")
);

function RoutingAuthentication(props) {
  const location = useLocation();
  const history = useHistory();

  const popupData = mainPopupData(
    (event, formVals) => confirmAccount(event, formVals, history),
    (event, formVals) => recoverPassword(event, formVals, history)
  );

  return (
    <Suspense fallback={<></>}>
      {![
        "/confirm",
        "/recover-password",
        "/account-confirm",
        "/terms-and-conditions",
        "/privacy-policy",
        "/cookie-policy",
        "/signup",
        "/recover",
      ].includes(location.pathname) && (
        <Redirect to={{ pathname: "/main", search: window.location.search }} />
      )}
      <Route
        path={["/main", "/signup", "/recover"]}
        exact
        render={() => (
          <MainView
            setUser={(token, plan) => {
              props.appInitialization(token);
            }}
            //    setError={this.setError}
            version={props.version}
            organization={props.organization}
            billing_data={props.billingData.data}
            payment_methods={props.billingData.paymentMethods}
          />
        )}
      />
      <Route
        path={"/terms-and-conditions"}
        exact
        render={() => <TermsConditionsView />}
      />
      <Route
        path={"/privacy-policy"}
        exact
        render={() => <PrivacyPolicyView />}
      />
      <Route
        path={"/cookie-policy"}
        exact
        render={() => <CookiePolicyView />}
      />
      <Route
        path="/account-confirm"
        render={(props) => (
          <MainView
            setUser={(token, plan) => {
              props.appInitialization(token);
            }}
            mode="confirm"
          />
        )}
      />
      <Route
        path="/confirm"
        exact
        render={(props) => <Popup data={popupData.emailConfirmPopupData} />}
      />
      <Route
        path="/recover-password"
        exact
        render={(props) => <Popup data={popupData.recoverAccountPopupData} />}
      />
    </Suspense>
  );
}

export default RoutingAuthentication;

import React, { Suspense } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Popup from "../components/Popup";
import ProtectedRoute from "../components/ProtectedRoute";
import setAlert from "../helpers/notifications/alerts";
import setNotification from "../helpers/notifications/notifications";
import planManager from "../helpers/organizationPlanData";
import permissionsManager from "../helpers/permissionsManager";
import i18n from "../i18n";
///Campaigns
///Links
const AllLinksView = React.lazy(() => import("../views/Links/AllLinks"));
const LinksView = React.lazy(() => import("../views/Links/ActiveLinks/index"));
const TemplatesView = React.lazy(() =>
  import("../views/Links/Templates/index")
);
const LinkShortenersView = React.lazy(() =>
  import("../views/Links/LinkShortenersView/index")
);

const BulkConfirmation = React.lazy(() =>
  import("../views/Links/BulkConfirmation/index")
);
const ParameterManagementView = React.lazy(() =>
  import("../views/Links/ParameterManagementView/index")
);
const ParamDependenciesNew = React.lazy(() =>
  import("../views/Links/ParameterDependenciesNew/index")
);
const ParameterDependencyVisualizerView = React.lazy(() =>
  import("../views/Links/ParameterDependencyVisualizerView/index")
);
const ParametersView = React.lazy(() =>
  import("../views/Links/Parameters/index")
);
const ParameterImportView = React.lazy(() =>
  import("../views/Links/Parameters/ParameterImportView/index")
);

const RoutingLinks = (props) => {
  return (
    <>
      <Route
        path={[
          "/links/active-links",
          "/links/active-links/create",
          "/links/active-links/edit",
          "/links/active-links/duplicate",
          "/links/active-links/delete",
          "/links/active-links/close-campaign",
          "/links/active-links/reopen-campaign",
          "/links/active-links/qr",
        ]}
        render={(props2) => (
          <LinksView
            setError={setAlert}
            user={props.user}
            organization={props.organization}
            teamSelected={props.teamSelected}
            teamRole={props.teamRole}
            setNotification={setNotification}
            plan={props.plan}
            lang={i18n.language}
          />
        )}
      />
      <ProtectedRoute
        path="/links/active-links/qr"
        protectedPaths={["/links/active-links/qr"]}
        exact
        authorized={planManager(props.plan, "qr_codes")}
        redirect="/error/426"
      />
      <ProtectedRoute
        path={[
          "/links/active-links/close-campaign",
          "/links/active-links/reopen-campaign",
        ]}
        protectedPaths={[
          "/links/active-links/close-campaign",
          "/links/active-links/reopen-campaign",
        ]}
        exact
        authorized={permissionsManager(
          "team",
          props.teamRole,
          "manageCampaigns"
        )}
        redirect="/error/403"
        render={(props2) => <></>}
      />
      <ProtectedRoute
        path={["/links/all-links", "/links/all-links/qr"]}
        authorized={permissionsManager(
          "org",
          props.user.orgAdmin,
          "viewAllTeamsLinks"
        )}
        redirect="/error/403"
        exact
        render={(props2) => (
          <AllLinksView
            setError={setAlert}
            user={props.user}
            teamRole={props.teamRole}
            teams={props.teams}
            organization={props.organization}
            teamSelected={props.teamSelected}
            plan={props.plan}
          />
        )}
      />
      <ProtectedRoute
        path={["/links/bulk-confirmation", "/links/bulk-confirmation/confirm"]}
        authorized={permissionsManager(
          "org",
          props.user.orgAdmin,
          "viewAllTeamsLinks"
        )}
        redirect="/error/403"
        exact
        render={(props2) => (
          <BulkConfirmation
            setError={setAlert}
            user={props.user}
            teamRole={props.teamRole}
            teams={props.teams}
            organization={props.organization}
            teamSelected={props.teamSelected}
            plan={props.plan}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/links/templates",
          "/links/templates/create",
          "/links/templates/edit",
          "/links/templates/duplicate",
          "/links/templates/delete",
        ]}
        authorized={true}
        exact
        render={(props2) => (
          <TemplatesView
            setError={setAlert}
            user={props.user}
            teamRole={props.teamRole}
            organization={props.organization}
            teamSelected={props.teamSelected}
            plan={props.plan}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/links/templates/create",
          "/links/templates/edit",
          "/links/templates/duplicate",
          "/links/templates/delete",
        ]}
        protectedPaths={[
          "/links/templates/create",
          "/links/templates/edit",
          "/links/templates/duplicate",
          "/links/templates/delete",
        ]}
        exact
        authorized={
          props.user.orgAdmin ||
          permissionsManager("team", props.teamRole, "manageTemplates")
        }
        redirect="/error/403"
      />
      <ProtectedRoute
        path={[
          "/links/parameters",
          "/links/parameters/delete",
          "/links/parameters/import/csv",
          "/links/parameters/import/ga",
        ]}
        authorized={true}
        exact
        render={(props2) => (
          <ParametersView
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            organization={props.organization}
            teamRole={props.teamRole}
            teamSelected={props.teamSelected}
          />
        )}
      />
      <ProtectedRoute
        path="/links/parameters/delete"
        protectedPaths={["/links/parameters/delete"]}
        exact
        authorized={
          props.user.orgAdmin ||
          permissionsManager("team", props.teamRole, "addParameters")
        }
        redirect="/error/403"
      />
      <ProtectedRoute
        path={["/links/parameters/import/csv", "/links/parameters/import/ga"]}
        exact
        redirect="/error/403"
        authorized={
          props.user.orgAdmin ||
          permissionsManager("org", props.user.orgAdmin, "importParameters")
        }
      />
      <ProtectedRoute
        path="/links/parameters/confirm-import"
        exact
        authorized={
          props.user.orgAdmin ||
          permissionsManager("org", props.user.orgAdmin, "importParameters")
        }
        redirect="/error/403"
        render={(props2) => (
          <ParameterImportView
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            teamRole={props.teamRole}
            setNotification={setNotification}
            organization={props.organization}
            teamSelected={props.teamSelected}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/links/parameter-management",
          "/links/parameter-management/migrate/content",
          "/links/parameter-management/migrate/term",
          "/links/parameter-management/confirm",
        ]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager("org", props.user.orgAdmin, "manageParameters")
        }
        redirect="/error/403"
        exact
        render={(props2) => (
          <ParameterManagementView
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            updateTeams={props.updateTeams}
            teamSelected={props.teamSelected}
            organization={props.organization}
            plan={props.plan}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/links/dependencies-management",
          "/links/dependencies-management/confirm",
        ]}
        protectedPaths={[
          "/links/dependencies-management",
          "/links/dependencies-management/confirm",
        ]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager("org", props.user.orgAdmin, "manageParameters")
        }
        redirect="/error/403"
        exact
        render={(props2) => (
          <ParamDependenciesNew
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            updateTeams={props.updateTeams}
            teamSelected={props.teamSelected}
            organization={props.organization}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/links/dependencies-management",
          "/links/dependencies-management/confirm",
        ]}
        protectedPaths={[
          "/links/dependencies-management",
          "/links/dependencies-management/confirm",
        ]}
        authorized={planManager(props.plan, "parameter_dependencies")}
        redirect="/error/426"
        exact
      />
      <ProtectedRoute
        path={["/links/dependency-mapping"]}
        protectedPaths={["/links/dependency-mapping"]}
        authorized={planManager(props.plan, "parameter_dependencies")}
        redirect="/error/426"
        exact
        render={(props2) => (
          <ParameterDependencyVisualizerView
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            updateTeams={props.updateTeams}
            teamSelected={props.teamSelected}
            organization={props.organization}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/links/link-shorteners",
          "/links/link-shorteners/edit",
          "/links/link-shorteners/edit-bitly",
          "/links/link-shorteners/edit-tinyurl",
          "/links/link-shorteners/add-bitly",
          "/links/link-shorteners/remove-bitly",
          "/links/link-shorteners/add-tinyurl",
          "/links/link-shorteners/remove-tinyurl",
          "/links/link-shorteners/auth/bitly",
          "/links/link-shorteners/delete",
        ]}
        authorized={permissionsManager(
          "org",
          props.user.orgAdmin,
          "manageLinkShorteners"
        )}
        redirect="/error/403"
        exact
        render={(props2) => (
          <LinkShortenersView
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            organization={props.organization}
            teamSelected={props.teamSelected}
            teamRole={props.teamRole}
          />
        )}
      />
    </>
  );
};

export default RoutingLinks;

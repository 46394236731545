import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Icon } from "rsuite";
import "./style.css";

function UpgradeRequired(props) {
  const id = Array.apply(null, Array(4))
    .map(function () {
      var c = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      return c.charAt(Math.random() * c.length);
    })
    .join("");
  const plan = props.plan;
  const locked = props.locked;

  const lockElements = (locked) => {
    if (!locked || !locked.length) {
      return;
    }
    locked.forEach((element) => {
      let el = document.querySelector(element);
      if (!el) {
        return;
      }
      el.classList.add("locked-container");
    });
  };
  window.setTimeout(() => lockElements(locked), 100);

  return (
    <Translation>
    {(t) => (
      <div>
        <Icon
          icon="lock"
          size="2x"
          data-tip
          data-for={`upgrade-required ${id}`}
          className="locked-feature-icon"
        />
        <ReactTooltip
          className="plan-upgrade"
          id={`upgrade-required ${id}`}
          place={"right"}
          type={"light"}
          effect={"solid"}
          multiline={true}
          delayShow={100}
          delayHide={100}
          clickable={true}
        >
          <div className="plan-upgrade-tooltip">
            <h4 className="plan-upgrade-header">
              {t("multiple.plans.text.funcionalidade_disp_plano")}{" "}
              <strong>{plan}</strong>.
            </h4>
            {props.admin && (
              <>
                <h4>
                  {t("multiple.plans.text.actualizar_plano")}{""}
                  {/*Poderá atualizar o seu plano em{" "}
                  <strong>Configurações {">>"} Planos de Produto</strong> .*/}
                </h4>
                <Link to="/settings/billings/plans">
                  <button className="action-button">{t("multiple.plans.view_plans")}</button>
                </Link>
              </>
            )}
          </div>
        </ReactTooltip>
      </div>
    )}
    </Translation>  
  );
}

export default UpgradeRequired;

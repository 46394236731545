import React from "react";
import { Link } from "react-router-dom";
import "./style.css";
import KeyGen from "../../../helpers/randomKeyGen";

function Breadcrumb(props) {
  return (
    <div className="breadcrumb">
      <Link to="/" className="breadcrumb-page">
        <h3>URL Builder </h3>
      </Link>
      {props.pages &&
        props.pages.map((page) => (
          <Link to={page.url} className="breadcrumb-page" key={KeyGen()}>
            <h3>/ {page.title} </h3>
          </Link>
        ))}
    </div>
  );
}

export default Breadcrumb;

import React, { useState, useEffect } from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { Divider, Icon } from "rsuite";

import "./style.css";

function TeamSelect(props) {
  const [visible, toggle] = useState(props.visible);
  const [inputValues, handleInputChange] = useState("");
  const teams = props.teams.sort((a, b) => {
    if (a.team_name.toLowerCase() > b.team_name.toLowerCase()) {
      return 1;
    }
    if (a.team_name.toLowerCase() < b.team_name.toLowerCase()) {
      return -1;
    }
    return 0;
  });
  
  useEffect(() => {
    toggle(props.visible);
  }, [props.visible]);

  useEffect(() => {
    if (
      !inputValues.length &&
      props.defaultValues &&
      props.defaultValues.length
    ) {
      handleInputChange(props.defaultValues);
    }
  }, []);

  return (
    <Translation>
      {(t, { i18n }) => (
        <div
          id={`multiple-select-team`}
          className={` team-selector ${(visible && "visible") || ""}`}
        >
          {/*
          <div className="orgs">
            <h4 className="team-selector-header">Organizations</h4>
            <div>
              <SelectPicker
                className="org-select"
                cleanable={false}
                searchable={false}
                value={props.selectedOrg}
                data={props.organizations.map((org) => {
                  return { label: org.name, value: org.id };
                })}
                onChange={(value) => {
                  window.localStorage.setItem("preferedOrg", value);
                  window.location.reload();
                }}
              />
            </div>
          </div>
          */}
          <div className="teams">
            <div className="selector-panel current-team-selector">
              <div>
                <h5 className="drawer-label">
                  {process.env.REACT_APP_OPT_EXPERIMENTAL && (
                    <Icon icon={"group"} style={{ marginRight: "0.5em" }} />
                  )}
                  {t("menu.barra_esquerda.header_dropdown.team_m")}
                </h5>
                <h4 className="drawer-team">
                  {(props.selected &&
                    props.teams.find((team) => team.id === props.selected)
                      .team_name) ||
                    t(
                      "visualizar_links_todas_as_equipas.header.placeholder.all_teams_no_team"
                    )}
                </h4>
              </div>
              <i className="material-icons gray-icon ">unfold_less</i>
            </div>
            <div className="selector-panel multiple-select-team">
              <h5 className="drawer-label">
                {t("menu.barra_esquerda.header_dropdown_value.teams_all")}
              </h5>
              {props.canManage && (
                <Link to="/teams/team-management">
                  <button
                    className="action-button  no-margin"
                    style={{ padding: "0.25em 0.5em" }}
                  >
                    {t(
                      "menu.barra_esquerda.header_dropdown_value.manage_teams"
                    )}
                  </button>
                </Link>
              )}
            </div>
            <div className="team-list">
              {teams
                .filter((team) => team.status === "active")
                .map((option) => {
                  if (props.canManage) {
                    option.role = "admin";
                  } else {
                    let role = props.teamRoles.find(
                      (team) => team.id === option.id
                    ).role;
                    option.role = role;
                  }
                  return option;
                })

                .map((option, index) => (
                  <div
                    key={option.id}
                    onClick={() => props.changeTeam(option.id)}
                    className={`team-option ${
                      (props.selected === option.id && "selected") || ""
                    }`}
                  >
                    <h5>{option.team_name}</h5>
                    {
                      <i
                        className="material-icons gray-icon info-icon icon-margin-left"
                        data-for="teams-info"
                        data-tip={`${
                          (option.role === "admin" && t("criar_e_gerir_utilizadores.tooltips.user_admin")) ||
                          (option.role === "leader" && t("criar_e_gerir_utilizadores.tooltips.user_leader")) ||
                          (option.role === "member" && t("criar_e_gerir_utilizadores.tooltips.user_member")) ||
                          (option.role === "viewer" && t("criar_e_gerir_utilizadores.tooltips.user_viewer"))
                        }`}
                      >
                        {(option.role === "admin" && "admin_panel_settings") ||
                          (option.role === "leader" && "supervisor_account") ||
                          (option.role === "member" && "person") ||
                          (option.role === "viewer" && "visibility")}
                      </i>
                    }
                  </div>
                ))}
            </div>
            <ReactTooltip
              className="team-info"
              id="teams-info"
              place={"right"}
              type={"light"}
              effect={"solid"}
              multiline={true}
              delayShow={500}
            ></ReactTooltip>
          </div>
        </div>
      )}
    </Translation>
  );
}

export default TeamSelect;

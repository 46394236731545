import React, { Suspense } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Popup from "../components/Popup";
import ProtectedRoute from "../components/ProtectedRoute";
import setAlert from "../helpers/notifications/alerts";
import setNotification from "../helpers/notifications/notifications";
import planManager from "../helpers/organizationPlanData";
import permissionsManager from "../helpers/permissionsManager";
///Trash
const CampaignLinksTrashView = React.lazy(() =>
  import("../views/Trash/CampaignAndLinks/index")
);
const TemplatesTrashView = React.lazy(() =>
  import("../views/Trash/Templates/index")
);

const RoutingTrash = (props) => {
  return (
    <>
      <ProtectedRoute
        path={[
          "/deleted/campaigns-links",
          "/deleted/campaigns-links/delete",
          "/deleted/campaigns-links/activate",
          "/deleted/campaigns-links/clone-link",
        ]}
        protectedPaths={[
          "/deleted/campaigns-links",
          "/deleted/campaigns-links/delete",
          "/deleted/campaigns-links/activate",
          "/deleted/campaigns-links/clone-link",
        ]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager("team", props.teamRole, "viewCampaignLinkTrash")
        }
        redirect="/error/403"
        exact
        render={() => (
          <CampaignLinksTrashView
            setError={setAlert}
            user={props.user}
            organization={props.organization}
            teamSelected={props.teamSelected}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/deleted/campaigns-links/delete",
          "/deleted/campaigns-links/activate",
          "/deleted/campaigns-links/clone-link",
        ]}
        protectedPaths={[
          "/deleted/campaigns-links/delete",
          "/deleted/campaigns-links/activate",
          "/deleted/campaigns-links/clone-link",
        ]}
        exact
        authorized={
          props.user.orgAdmin ||
          permissionsManager("team", props.teamRole, "manageCampaignLinkTrash")
        }
        redirect="/error/403"
      />
      <ProtectedRoute
        path={[
          "/deleted/templates",
          "/deleted/templates/delete",
          "/deleted/templates/activate",
        ]}
        protectedPaths={[
          "/deleted/templates",
          "/deleted/templates/delete",
          "/deleted/templates/activate",
        ]}
        authorized={
          props.user.orgAdmin ||
          permissionsManager("team", props.teamRole, "viewTemplateTrash")
        }
        redirect="/error/403"
        exact
        render={() => (
          <TemplatesTrashView
            setError={setAlert}
            user={props.user}
            organization={props.organization}
            teamSelected={props.teamSelected}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={["/deleted/templates/delete", "/deleted/templates/activate"]}
        protectedPaths={[
          "/deleted/templates/delete",
          "/deleted/templates/activate",
        ]}
        exact
        authorized={
          props.user.orgAdmin ||
          permissionsManager("team", props.teamRole, "manageTemplateTrash")
        }
        redirect="/error/403"
      />
    </>
  );
};

export default RoutingTrash;

import React, { Component, Suspense, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export const sendDatalayerEvent = (event, standard, custom) => {
  const dataLayer = window.dataLayer || [];
  console.log({
    event: event,
    category: standard.category,
    action: standard.action,
    name: standard.name,
    value: standard.value,
    ...custom,
  });
  dataLayer.push({
    event: event,
    category: standard.category,
    action: standard.action,
    event_name: standard.name,
    value: standard.value,
    ...custom,
  });
};

const setGlobalDimensions = (user, plan) => {
  let dataLayer = window.dataLayer || [];
  let paths = window.location.pathname.split("/");
  let dimensions = {
    product: "urlbuilder.online",
    subdomainLocation: "app.urlbuilder.online",
    path2: paths[1],
    path3: paths[2],
    path4: paths[3],
    path5: paths[4],
    logged: user ? "true" : "false",
    userId: user ? user.id : null,
  };
  if (user) {
    dimensions.emailDomain = user.email.split("@")[1];
  }
  if (plan) {
    dimensions.licencingPlan = plan.plan;
    dimensions.licensingCycle = plan.cycle;
  }
  console.log({ dimensions });
  dataLayer.push({
    ...dimensions,
  });
};

export const PiwikGlobals = (props) => {
  const { user, plan } = props;
  const location = useLocation();

  useEffect(() => {
    setGlobalDimensions(user, plan);
  }, [location, user, plan]);

  return <></>;
};

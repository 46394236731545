import i18n from "../../i18n";
import { confirmRecovery, confirmUser, getUserData } from "../../services/user";
import setAlert from "../notifications/alerts";
import { setDebugNotification } from "../notifications/debug";

//Function that returns processed user data
const getUserInformation = async (token) => {
  return getUserData(token)
    .then((response) => {
      //In case of an empty response, return a rejected promise
      if (!response) return Promise.reject("No data retrieved");
      //Initialize the main user object
      let baseUser = {
        orgs: [],
        teams: [],
        date_format: "AAAA/MM/DD",
        time_format: "12",
      };

      //Set the user ID to the response userId
      baseUser.id = response.userId;
      baseUser.username =
        response.credentials.username || response.credentials.userHandle;

      //Spread the data in response credentials into the user object
      let user = { ...baseUser, ...response.credentials };

      return Promise.resolve(user);
    })
    .catch((error) => {
      setDebugNotification(error);
      return Promise.reject("Error getting user information");
    });
};

const recoverPassword = (event, formVals, history) => {
  var urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("oobCode");
  let mode = urlParams.get("mode");
  event.preventDefault();
  confirmRecovery({
    ...formVals,
    code: code,
    mode: mode,
    lang: i18n.language,
  })
    .then((response) => {
      setAlert("Password changed successfully", "", "success");
      window.setTimeout(() => {
        history.push("/");
      }, 3000);
    })
    .catch((error) => {
      setAlert("Could not confirm account");
      setDebugNotification(error);
      window.setTimeout(() => window.location.reload(), 3000);
    });
};

//Function that confirms the account
const confirmAccount = (event, formVals, history) => {
  var urlParams = new URLSearchParams(window.location.search);
  let code = urlParams.get("oobCode");
  let email = urlParams.get("email");

  if (!code || !email)
    return setAlert("Could not confirm account", "", "error");

  event.preventDefault();
  confirmUser({
    ...formVals,
    code: code,
    lang: i18n.language,
    email: email,
  })
    .then((response) => {
      setAlert("Password changed successfully", "", "success");
      window.setTimeout(() => {
        history.push("/");
      }, 3000);
    })
    .catch((error) => {
      setAlert("Could not confirm account");
      setDebugNotification(error);
      window.setTimeout(() => window.location.reload(), 3000);
    });
};

export { getUserInformation, recoverPassword, confirmAccount };

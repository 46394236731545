import React, { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import TeamSelect from "../../TeamSelector";
import { Link } from "react-router-dom";
import { Translation } from "react-i18next";
import menuHide from "../../../helpers/menuHide";
import permissionsManager from "../../../helpers/permissionsManager";
import { Animation, Icon, Button, Whisper, IconButton, Tag } from "rsuite";
import planManager from "../../../helpers/organizationPlanData";
import "./style.css";
import UpgradeRequired from "../../UpgradeRequired";
import PlaceholderGraph from "rsuite/lib/Placeholder/PlaceholderGraph";
import PlaceholderParagraph from "rsuite/lib/Placeholder/PlaceholderParagraph";
import MiniChangelog from "../../MiniChangelog";
import i18n from "../../../i18n";

const SlidingDrawer = (props) => {
  const OPT_HELP = process.env.REACT_APP_OPT_HELP_DESK;
  const OPT_AUDIT = process.env.REACT_APP_OPT_AUDIT_TOOL;
  const OPT_CHANGELOG = process.env.REACT_APP_OPT_CHANGELOG;
  const drawer = props.drawer;
  const expanded = props.expanded;

  const [teamAnimation, setTeamAnimation] = useState(false);
  const [teamSelector, setTeamSelector] = useState(false);
  const [currentDrawerData, setDrawerData] = useState(undefined);
  const [previous, setPrevious] = useState(undefined);
  const [path, setPath] = useState("");
  const organization =
    (props.organization && props.organization.name) ||
    window.sessionStorage.getItem("tempOrg");
  const teamName = props.teamSelected && props.teamSelected.team_name;

  const drawerData = [
    {
      drawer: "dashboard",
      data:
        (OPT_AUDIT && [
          {
            label:
              "criar_e_gerir_utilizadores.permissions_table.text.dashboard_camps",
            options: [
              {
                name: "criar_e_gerir_utilizadores.permissions_table.text.dashboard_camps",
                link: "/dashboard",
              },
            ],
          },
          {
            label: "FERRAMENTAS DE AUDIT",
            options: [
              {
                name: "Logs",
                link: "/audit",
                permission: {
                  type: "org",
                  role: props.orgRole,
                  name: "manageCampaignParameters",
                },
              },
            ],
          },
        ]) ||
        [],
    },
    {
      drawer: "campaigns",
      data: [
        {
          label: "menu.barra_esquerda.level_2_group.manage_camps_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.camps",
              link: "/campaigns/active-campaigns",
            },
          ],
        },
        {
          label: "menu.barra_esquerda.level_2_group.manage_structure_camps_m",
          options: [
            {
              name: "taxonomia_de_campanhas.new_taxonomy.text.ca_params",
              link: "/campaigns/parameter-management",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageCampaignParameters",
              },
              plan: "campaign_parameters",
              required: "Professional",
            },
            {
              name: "menu.barra_esquerda.level_2.cas_taxonomie",
              link: "/campaigns/campaign-taxonomy",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageCampaignParameters",
              },
              plan: "campaign_taxonomy",
              required: "Professional",
            },
            {
              name: "menu.barra_esquerda.level_2.dependencies_parameters",
              link: "/campaigns/dependencies-management",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageCampaignParameters",
              },
              plan: "parameter_dependencies",
              required: "Professional",
            },
            {
              name: "menu.barra_esquerda.level_2.dependencies_map",
              link: "/campaigns/dependency-mapping",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageCampaignParameters",
              },
              plan: "parameter_dependencies",
              required: "Professional",
            },
          ],
        },
        {
          label:
            "menu.barra_esquerda.level_2_group.manage_camps_parameter_value_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.cas_parameters_values",
              link: "/campaigns/parameters",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageCampaignParameters",
              },
              plan: "campaign_parameters",
              required: "Professional",
            },
            {
              name: "menu.barra_esquerda.level_2.import_via_csv",
              link: "/campaigns/parameters/import/csv",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageCampaignParameters",
              },
              plan: "campaign_parameters",
              required: "Professional",
            },
          ],
        },
        {
          label: "menu.barra_esquerda.level_2_group.archive_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.archive_cas_lis",
              link: "/campaigns/archive",
              permission: {
                type: "team",
                role: props.teamRole,
                name: "viewArchivedCampaigns",
              },
            },
          ],
        },
      ],
    },
    {
      drawer: "links",
      data: [
        {
          label: "menu.barra_esquerda.level_2_group.admin_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.lis_all_teams",
              link: "/links/all-links",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "viewAllTeamsLinks",
              },
              highlight: true,
              info: "Esta Página permite apenas visualizar os links criados por todas as equipas, se desejar criar ou editar esses links, pode fazê-lo em Criar e Gerir Links.",
            },
          ],
        },
        {
          label:
            "criar_e_gerir_utilizadores.permissions_table.text.manage_links",
          options: [
            {
              name: "menu.barra_esquerda.level_2.lis",
              link: "/links/active-links",
            },
            {
              name: "menu.barra_esquerda.level_2.temps",
              link: "/links/templates",
            },
            {
              name: "menu.barra_esquerda.level_2.link_shorteners",
              link: "/links/link-shorteners",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageLinkShorteners",
              },
            },
          ],
        },
        {
          label: "menu.barra_esquerda.level_2_group.manage_utm_parameters_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.utm_parameter",
              link: "/links/parameter-management",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageParameters",
              },
            },
            {
              name: "menu.barra_esquerda.level_2.dependencies_parameters",
              link: "/links/dependencies-management",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageParameters",
              },
              plan: "parameter_dependencies",
              required: "Professional",
            },
            {
              name: "menu.barra_esquerda.level_2.dependencies_map",
              link: "/links/dependency-mapping",
              plan: "parameter_dependencies",
              required: "Professional",
            },
          ],
        },
        {
          label:
            "menu.barra_esquerda.level_2_group.manage_utm_parameter_value_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.utm_parameters_values",
              link: "/links/parameters",
            },
            {
              name: "menu.barra_esquerda.level_2.import_via_csv",
              link: "/links/parameters/import/csv",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "importParameters",
              },
            },
            {
              name: "menu.barra_esquerda.level_2.import_via_analytics",
              link: "/links/parameters/import/ga",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "importParameters",
              },
            },
          ],
        },
      ],
    },
    {
      drawer: "teams",
      data: [
        {
          label: "menu.barra_esquerda.level_2_group.manage_teams_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.teams",
              link: "/teams/team-management",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageAllTeams",
              },
            },
          ],
        },
        {
          label: "menu.barra_esquerda.level_2_group.manage_users_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.users",
              link: "/teams/user-management",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageAllUsers",
              },
            },
          ],
        },
      ],
    },
    {
      drawer: "trash",
      data: [
        {
          label: "menu.barra_esquerda.level_2_group.trash_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.trash_camps_links",
              link: "/deleted/campaigns-links",
            },
            {
              name: "menu.barra_esquerda.level_2.trash_templates",
              link: "/deleted/templates",
            },
          ],
        },
      ],
    },
    {
      drawer: "help",
      data:
        (OPT_HELP && [
          {
            label: "menu.barra_esquerda.level_2_group.start_m",
            options: [
              {
                name: "menu.barra_esquerda.level_2.help_center",
                link: "/help",
                external: "http://help.urlbuilder.online/",
              },
            ],
          },
          {
            label: "menu.barra_esquerda.level_2_group.help_articles",
            options: [
              {
                name: "menu.barra_esquerda.level_2.getting_started",
                link: '/help?articles="gettingStarted"',
                external:
                  "http://help.urlbuilder.online/?doc_category=getting-started",
              },
              {
                name: "menu.barra_esquerda.level_2.educational",
                link: '/help?articles="educational"',
                external:
                  "http://help.urlbuilder.online/?doc_category=educational",
              },
              {
                name: "menu.barra_esquerda.level_2.camps",
                link: '/help?articles="campaigns"',
                external:
                  "http://help.urlbuilder.online/?doc_category=campaigns",
              },
              {
                name: "menu.barra_esquerda.level_2.params",
                link: '/help?articles="parameters"',
                external:
                  "http://help.urlbuilder.online/?doc_category=parameters",
              },
              {
                name: "menu.barra_esquerda.level_2.lis",
                link: '/help?articles="links"',
                external: "http://help.urlbuilder.online/?doc_category=links",
              },
              {
                name: "menu.barra_esquerda.level_2.link_shorteners",
                link: '/help?articles="urlShorteners"',
                external:
                  "http://help.urlbuilder.online/?doc_category=url-shorteners",
              },
              {
                name: "menu.barra_esquerda.level_2.template",
                link: '/help?articles="templates"',
                external:
                  "http://help.urlbuilder.online/?doc_category=templates",
              },
              {
                name: "menu.barra_esquerda.level_2.teams",
                link: '/help?articles="teams"',
                external: "http://help.urlbuilder.online/?doc_category=teams",
              },
              {
                name: "menu.barra_esquerda.level_2.features",
                link: '/help?articles="features"',
                external:
                  "http://help.urlbuilder.online/?doc_category=features",
              },
              {
                name: "menu.barra_esquerda.level_2.managem_workflow",
                link: '/help?articles="workflowManagement"',
                external:
                  "http://help.urlbuilder.online/?doc_category=workflow-management",
              },
            ],
          },
        ]) ||
        [],
    },
    {
      drawer: "settings",
      data: [
        {
          label: "menu.barra_esquerda.level_2_group.data_quality",
          options: [
            {
              name: "menu.barra_esquerda.level_2.syntax_errors_minimize",
              link: "/settings/syntax-config",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageSyntaxConfig",
              },
            },
          ],
        },
        {
          label: "menu.barra_esquerda.level_2_group.look_and_feel",
          options: [
            {
              name: "menu.barra_esquerda.level_2.preferences",
              link: "/settings/preferences",
            },
          ],
        },
        {
          label: "perfil.setting.text.orgs",
          options: [
            {
              name: "menu.barra_esquerda.level_2.manage_org",
              link: "/settings/organization",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageOrg",
              },
            },
          ],
        },
        {
          label: "menu.barra_esquerda.level_2_group.profile_m",
          options: [
            {
              name: "menu.barra_esquerda.level_2.manage_profile",
              link: "/settings/profile",
            },
            {
              name: "menu.barra_esquerda.level_2.password_change",
              link: "/settings/change-password",
            },
          ],
        },
        {
          label: "menu.barra_esquerda.level_2_group.billing",
          options: [
            {
              name: "menu.barra_esquerda.level_2.manage_plan",
              link: "/settings/billings/current-plan",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageBillings",
              },
            },
            {
              name: "menu.barra_esquerda.level_2.billing_data",
              link: "/settings/billings/information",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageBillings",
              },
            },

            {
              name: "menu.barra_esquerda.level_2.billing_plans",
              link: "/settings/billings/plans",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageBillings",
              },
            },

            {
              name: "menu.barra_esquerda.level_2.billing_history",
              link: "/settings/billings/history",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageBillings",
              },
            },
          ],
        },
      ],
    },
  ];

  /*
  {
              name: "Contactos de Facturação",
              link: "/settings/billings/contacts",
              permission: {
                type: "org",
                role: props.orgRole,
                name: "manageBillings",
              },
            },
             */

  useEffect(() => {
    setPath(window.location.pathname);
    const tab = getInitialDrawer(window.location.pathname);
    props.setNavTab(tab);
    setPrevious(window.sessionStorage.getItem("last-page-name"));
    document.title = `${getPageTitle(tab)} urlbuilder.online`;
  }, [window.location.pathname]);

  useEffect(() => {
    let data = drawerData.find((dData) => dData.drawer === drawer);
    setDrawerData(data);
    ReactTooltip.rebuild();
  }, [drawer]);

  useEffect(() => {
    if (document.getElementsByClassName("view")[0]) {
      let className = (expanded && "view small") || "view";
      document.getElementsByClassName("view")[0].className = className;
    }
  }, [expanded]);

  const getPageTitle = (tab) => {
    switch (tab) {
      case "dashboard":
        return `${i18n.t("menu.barra_esquerda.level_1.dashboard")} -`;
      case "campaigns":
        return `${i18n.t("menu.barra_esquerda.level_2.camps")} -`;
      case "links":
        return `${i18n.t("menu.barra_esquerda.level_2.lis")} -`;
      case "teams":
        return `${i18n.t("menu.barra_esquerda.level_2.teams")} -`;
      case "help":
        return `${i18n.t("menu.barra_esquerda.level_1.help")} -`;
      case "trash":
        return `${i18n.t("menu.barra_esquerda.level_1.trash")} -`;
      case "settings":
        return `${i18n.t("menu.barra_esquerda.level_1.config")} -`;
      default:
        return "";
    }
  };
  const getInitialDrawer = (path) => {
    if (path.includes("/dashboard") || path.includes("/audit")) {
      return "dashboard";
    } else if (path.includes("/deleted")) {
      return "trash";
    } else if (path.includes("/campaigns")) {
      return "campaigns";
    } else if (path.includes("/links")) {
      return "links";
    } else if (path.includes("/teams")) {
      return "teams";
    } else if (path.includes("/help")) {
      return "help";
    } else if (path.includes("/settings")) {
      return "settings";
    }
  };

  return (
    <Translation>
      {(t) => (
        <div
          className={"sliding-drawer" + ((expanded && " open") || " closed")}
        >
          <IconButton
            className="drawer-toggle-button"
            onClick={() => props.setExpanded(!expanded)}
            icon={<Icon icon="chevron-left" />}
            circle
            size="sm"
          />

          {expanded && (
            <React.Fragment>
              <div className="org-panel panel">
                <h5 className="drawer-label">
                  {process.env.REACT_APP_OPT_EXPERIMENTAL && (
                    <Icon icon={"building"} style={{ marginRight: "0.5em" }} />
                  )}
                  {t("menu.barra_esquerda.header.org_m")}
                </h5>
                {(organization && (
                  <h3 className="drawer-title">
                    {false && window.location.hostname === "localhost" && (
                      <Tag className="dev-tag">DEV</Tag>
                    )}
                    {organization}
                    {permissionsManager("org", props.orgRole, "manageOrg") ? (
                      <Link
                        to="/settings/organization"
                        className="org-edit-link"
                      >
                        <IconButton
                          className="org-edit-button"
                          icon={<Icon icon="pencil" />}
                          circle
                          size="sm"
                        />
                      </Link>
                    ) : null}
                  </h3>
                )) || (
                  <PlaceholderParagraph
                    active
                    className="text-placeholder"
                    rows={1}
                    rowHeight={24}
                    rowMargin={10}
                  />
                )}
              </div>
              {(props.user && (
                <div
                  className={`team-panel panel multiple-select-team`}
                  onClick={() => {
                    setTeamSelector(true);
                    setTeamAnimation(true);
                    menuHide(`multiple-select-team`, () =>
                      setTeamAnimation(false)
                    );
                  }}
                >
                  <div>
                    <h5 className="drawer-label">
                      {process.env.REACT_APP_OPT_EXPERIMENTAL && (
                        <Icon icon={"group"} style={{ marginRight: "0.5em" }} />
                      )}
                      {t("menu.barra_esquerda.header_dropdown.team_m")}
                    </h5>
                    <h4 className="drawer-team">
                      {teamName ||
                        t(
                          "visualizar_links_todas_as_equipas.header.placeholder.all_teams_no_team"
                        )}
                    </h4>
                  </div>
                  <i className="material-icons gray-icon ">unfold_more</i>
                </div>
              )) || (
                <PlaceholderGraph
                  className="team-placeholder"
                  active
                  width={224}
                  height={54}
                />
              )}
              {props.user && props.teams && (
                <Animation.Collapse
                  in={teamAnimation}
                  onExited={() => setTeamSelector(false)}
                >
                  <TeamSelect
                    organizations={props.user.orgs}
                    teams={
                      (props.orgRole && props.teams) ||
                      props.teams.filter((team) =>
                        props.user.teams.find(
                          (userTeam) => userTeam.id === team.id
                        )
                      )
                    }
                    teamRoles={props.user.teams}
                    canManage={permissionsManager(
                      "org",
                      props.orgRole,
                      "manageAllTeams"
                    )}
                    id={props.user.id}
                    visible={teamSelector}
                    selected={props.teamSelected.id}
                    selectedOrg={props.organization.id}
                    changeTeam={props.changeTeam}
                  />
                </Animation.Collapse>
              )}
              {false && (
                <div className="return-panel panel">
                  <h5 className="drawer-label">RETURN TO</h5>
                  <h4 className="drawer-return">
                    <Link to={window.sessionStorage.getItem("last-page")}>
                      <Icon icon="chevron-circle-left" />
                      {previous}
                    </Link>
                  </h4>
                </div>
              )}
              <div className="options-list">
                {currentDrawerData &&
                  props.plan &&
                  currentDrawerData.data.map((data) => (
                    <div
                      className="option-panel panel no-padding"
                      key={data.label}
                    >
                      {(data.options.filter((option) => {
                        if (!option.permission || !props.user) {
                          return true;
                        }
                        return permissionsManager(
                          option.permission.type,
                          (option.permission.type === "org" && props.orgRole) ||
                            (option.permission.type === "team" &&
                              props.teamRole),
                          option.permission.name
                        );
                      }).length && (
                        <>
                          {(props.user && (
                            <h5 className="drawer-label option-label">
                              {t(data.label).toUpperCase()}
                            </h5>
                          )) || (
                            <PlaceholderParagraph
                              className="text-placeholder placeholder-label"
                              rows={1}
                              rowMargin={10}
                              active
                            />
                          )}
                        </>
                      )) ||
                        null}
                      {data.options
                        .filter((option) => {
                          if (!option.permission || !props.user) {
                            return true;
                          }
                          return permissionsManager(
                            option.permission.type,
                            (option.permission.type === "org" &&
                              props.orgRole) ||
                              (option.permission.type === "team" &&
                                props.teamRole),
                            option.permission.name
                          );
                        })
                        .map((option) => (
                          <React.Fragment key={option.link}>
                            {(props.user && (
                              <>
                                {(option.external && (
                                  <a
                                    href={option.external}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    {" "}
                                    <div
                                      className={`drawer-option ${
                                        (option.highlight &&
                                          "action-button-2") ||
                                        ""
                                      } ${
                                        (option.link === path &&
                                          !option.highlight &&
                                          "selected") ||
                                        (option.link === path &&
                                          option.highlight &&
                                          "highlight-selected") ||
                                        ""
                                      }`}
                                    >
                                      <h4>{t(option.name)} </h4>
                                      {option.info && (
                                        <i
                                          data-for="drawer"
                                          data-tip={t(option.info)}
                                          className="material-icons gray-icon tooltip"
                                        >
                                          info
                                        </i>
                                      )}
                                      {option.external && (
                                        <Icon icon="external-link-square" />
                                      )}
                                      {option.plan &&
                                        planManager(props.plan, option.plan) ===
                                          false && (
                                          <UpgradeRequired
                                            plan={option.required}
                                            admin={props.orgRole}
                                            locked={[
                                              `a[id="${option.link}"] div h4`,
                                            ]}
                                          />
                                        )}
                                    </div>
                                  </a>
                                )) || (
                                  <Link
                                    to={
                                      ((!option.plan && true) ||
                                        planManager(props.plan, option.plan)) &&
                                      option.link
                                    }
                                    target={option.external && "_blank"}
                                    id={option.link}
                                    onClick={() => {
                                      window.sessionStorage.setItem(
                                        "last-page",
                                        window.location.pathname
                                      );
                                      document.getElementsByClassName(
                                        "page-title"
                                      )[0] &&
                                        window.sessionStorage.setItem(
                                          "last-page-name",
                                          document.getElementsByClassName(
                                            "page-title"
                                          )[0].textContent
                                        );
                                    }}
                                  >
                                    {" "}
                                    <div
                                      className={`drawer-option ${
                                        (option.highlight &&
                                          "action-button-2") ||
                                        ""
                                      } ${
                                        (option.link === path &&
                                          !option.highlight &&
                                          "selected") ||
                                        (option.link === path &&
                                          option.highlight &&
                                          "highlight-selected") ||
                                        ""
                                      }`}
                                    >
                                      <h4>{t(option.name)} </h4>
                                      {option.info && (
                                        <i
                                          data-for="drawer"
                                          data-tip={t(option.info)}
                                          className="material-icons gray-icon tooltip"
                                        >
                                          info
                                        </i>
                                      )}
                                      {option.external && (
                                        <Icon icon="external-link-square" />
                                      )}
                                      {option.plan &&
                                        planManager(props.plan, option.plan) ===
                                          false && (
                                          <UpgradeRequired
                                            plan={option.required}
                                            admin={props.orgRole}
                                            locked={[
                                              `a[id="${option.link}"] div h4`,
                                            ]}
                                          />
                                        )}
                                    </div>
                                  </Link>
                                )}
                              </>
                            )) || (
                              <PlaceholderGraph
                                className="team-placeholder drawer-placeholder"
                                active
                                width={200}
                                height={34}
                              />
                            )}
                          </React.Fragment>
                        ))}
                    </div>
                  ))}{" "}
                <ReactTooltip
                  className="drawer-popup"
                  id="drawer"
                  place={"right"}
                  type={"light"}
                  effect={"solid"}
                  delayShow={100}
                ></ReactTooltip>
              </div>
              <h6 className="version">
                {(OPT_CHANGELOG && (
                  <Whisper
                    trigger="click"
                    placement="top"
                    speaker={(props, ref) => {
                      const { className, left, top, onClose } = props;
                      return (
                        <MiniChangelog
                          style={{ left, top }}
                          onClose={onClose}
                          className={className}
                          ref={ref}
                          currentVersion={props.version}
                        />
                      );
                    }}
                  >
                    <Button>
                      <strong style={{ color: "black" }}>
                        URL Builder {props.version} - {props.versionDate}
                      </strong>
                    </Button>
                  </Whisper>
                )) || (
                  <>
                    URL Builder {props.version} - {props.versionDate}
                  </>
                )}
              </h6>
            </React.Fragment>
          )}
        </div>
      )}
    </Translation>
  );
};

export default SlidingDrawer;

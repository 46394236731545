const menuHideCallback = (event, element, callback) => {
  const clickedMenu = event.target.closest("." + element);
  const guide = event.target.closest(".guide");

  if (typeof event.target.className !== "string") {
    return false;
  }
  if (
    clickedMenu === null &&
    guide === null &&
    !event.target.className.includes(element)
  ) {
    const menu = document.getElementById(element);
    if (menu) {
      menu.classList.remove("open");
      if (callback) {
        callback();
      }
    }
    return true;
  }
  return false;
};

const menuHide = (element, callback) => {
  window.addEventListener(
    "click",
    function handler(event) {
      const action = menuHideCallback(event, element, callback);
      action && this.removeEventListener("click", handler);
    },
    {
      passive: true,
    }
  );
};

export default menuHide;

import React from "react";
import { Link } from "react-router-dom";
import { Button, Divider, Panel, PanelGroup, Tag } from "rsuite";
import versionData from "./../../helpers/versionData.json";
import "./style.css";

const MiniChangelog = React.forwardRef(({ style, onClose, ...rest }, ref) => {
  const styles = {
    ...style,
    background: "#fff",
    zIndex: 100,
    width: 230,
    padding: "0.5em",
    borderRadius: 4,
    top: "auto",
    bottom: "50px",
    position: "absolute",
    border: "1px solid #ddd",
    boxShadow: "0 3px 6px -2px rgba(0, 0, 0, 0.6)",
  };

  return (
    <div {...rest} style={styles} ref={ref}>
      <div className="mini-changelog-header">
        <h4 className="drawer-label">RECENT CHANGES</h4>
        <Link to="/help?changelog=true">
          <button className="action-button no-margin" onClick={onClose}>
            View More
          </button>
        </Link>
      </div>
      <Divider />
      <PanelGroup accordion bordered className="mini-changelog-list">
        {versionData.map((change, index) => (
          <Panel
            header={
              <h5>
                <Tag className={"current"}>{change.version}</Tag>
                {change.highlight}
              </h5>
            }
            eventKey={index + 1}
          >
            <h4>{change.description}</h4>
          </Panel>
        ))}
      </PanelGroup>
    </div>
  );
});

export default MiniChangelog;

import React from "react";

import Breadcrumb from "./Breadcrumb";
import "./style.css";

function Header(props) {
  return (
    <div>
      <Breadcrumb pages={props.pages} />
      <h1 className="page-title">{props.title}</h1>
    </div>
  );
}

export default Header;

import React, { useContext } from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";
import {
  Avatar,
  Divider,
  Dropdown,
  Icon,
  Placeholder,
  Popover,
  Whisper,
} from "rsuite";
import resetAuthData from "../../helpers/authDataReset";
import i18n from "../../i18n";

import "./style.css";

const countryNames = {
  pt: "Português",
  gb: "English (UK)",
  en: "English ",
  es: "Español",
  ca: "Català",
  fr: "Français",
  de: "Deutsche",
  it: "Italiano",
  pl: "Polskie",
  ro: "Română",
  ru: "Pусский",
  tr: "Türk",
};

const Menu = (props) => (
  <Popover {...props}>
    <Translation>
      {(t) => (
        <div id="user-menu" className="user-menu">
          <div className="user-menu-details">
            <Avatar
              circle
              src={(props.user && props.user.imageUrl) || ""}
              size="md"
            >
              {props.user
                ? props.user.username
                    .split(" ")
                    .filter((word, index) => index < 2)
                    .map((word) => word[0].toUpperCase())
                : ""}
            </Avatar>
            <div className="user-menu-info">
              {props.user ? (
                <h4 className="user-menu-name">{props.user.username}</h4>
              ) : (
                <Placeholder.Paragraph
                  rows={1}
                  rowHeight={16}
                  className="text-placeholder"
                />
              )}
              {props.user ? (
                <h5 className="user-menu-email">{props.user.email}</h5>
              ) : (
                <Placeholder.Paragraph
                  rows={1}
                  rowHeight={10}
                  className="text-placeholder"
                />
              )}
            </div>
          </div>
          <Divider />
          <div className="user-menu-options">
            <Link to="/settings/profile" className="user-menu-option">
              <h4>
                {process.env.REACT_APP_OPT_EXPERIMENTAL && (
                  <Icon
                    icon="user-o"
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                )}
                {t("menu.barra_topo.dropdown.profile")}
              </h4>
            </Link>
            <Link to="/settings/change-password" className="user-menu-option">
              <h4>
                {process.env.REACT_APP_OPT_EXPERIMENTAL && (
                  <Icon
                    icon="asterisk"
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                )}
                {t("menu.barra_esquerda.level_2.password_change")}
              </h4>
            </Link>
            <Link to="/settings/preferences" className="user-menu-option">
              <h4>
                {process.env.REACT_APP_OPT_EXPERIMENTAL && (
                  <Icon
                    icon="sliders"
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                )}
                {t("menu.barra_esquerda.level_2.preferences")}
              </h4>
            </Link>
          </div>
          <Divider />
          <div className="user-menu-options">
            <div className="user-menu-option logout">
              <h4
                onClick={() => {
                  resetAuthData();
                }}
              >
                {process.env.REACT_APP_OPT_EXPERIMENTAL && (
                  <Icon
                    icon="sign-out"
                    style={{ marginRight: "10px", fontSize: "16px" }}
                  />
                )}
                {t("menu.barra_topo.dropdown.session_logout")}
              </h4>
            </div>
          </div>
          <Divider />
          <Dropdown
            className="country-select-dropdown"
            renderTitle={() => (
              <div className="country-select">
                <h4>
                  {process.env.REACT_APP_OPT_EXPERIMENTAL && (
                    <Icon
                      icon="globe"
                      style={{ marginRight: "10px", fontSize: "16px" }}
                    />
                  )}
                  {countryNames[i18n.language]}
                </h4>
                <Icon
                  icon="globe"
                  style={{ fontSize: "20px", marginRight: "0.5em" }}
                />
              </div>
            )}
            placement="leftStart"
            noCaret
          >
            {["pt", "en", "es", "fr", "pl"].map((country) => (
              <Dropdown.Item
                key={country}
                onSelect={() => {
                  sessionStorage.setItem("tempLang", country);
                  window.location.reload();
                }}
              >
                <div className="country-select">
                  <h4>{countryNames[country]}</h4>
                </div>
              </Dropdown.Item>
            ))}
          </Dropdown>
          <div className="theme-switcher">
            <div
              className={`theme-preview klein ${
                props.theme === "klein_blue" && "selected"
              }`}
              onClick={() => props.setTheme("klein_blue")}
            ></div>
            <div
              className={`theme-preview intense ${
                props.theme === "autumn_green" && "selected"
              }`}
              onClick={() => props.setTheme("autumn_green")}
            ></div>
            <div
              className={`theme-preview lightning ${
                props.theme === "lightning_grey" && "selected"
              }`}
              onClick={() => props.setTheme("lightning_grey")}
            ></div>
            <div
              className={`theme-preview apricot ${
                props.theme === "summer_blossom" && "selected"
              }`}
              onClick={() => props.setTheme("summer_blossom")}
            ></div>
            <div
              className={`theme-preview cyberred ${
                props.theme === "cyberred" && "selected"
              }`}
              onClick={() => props.setTheme("cyberred")}
            ></div>
          </div>
          <Divider />
          <div className="menu-legal">
            <Link to="/terms-and-conditions">
              {t("signup.signup_form.text.terms_and_conditions_2")}
            </Link>
            <Link to="/privacy-policy">
              {t("signup.signup_form.text.terms_and_conditions_4")}
            </Link>
          </div>
          {/*
          <button onClick={() => props.toggleDark()}>Dark theme</button>
          */}
        </div>
      )}
    </Translation>
  </Popover>
);

function UserMenu(props) {
  return (
    <Whisper
      trigger="click"
      placement="bottomEnd"
      speaker={<Menu {...props} />}
    >
      <div>
        <Avatar
          circle
          src={(props.user && props.user.imageUrl) || ""}
          size="md"
        >
          {props.user
            ? props.user.username
                .split(" ")
                .filter((word, index) => index < 2)
                .map((word) => word[0].toUpperCase())
            : ""}
        </Avatar>
      </div>
    </Whisper>
  );
}

/*
 "lightning_grey": {
      "color1": "#1E1F23",
      "color2": "#666666",
      "color3": "#51D0DE",
      "colorButton": "rgba(0,0,0,0)"
    },
    */

export default UserMenu;

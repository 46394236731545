import React, { useEffect, useState } from "react";
import { Translation } from "react-i18next";
import { Button, ButtonToolbar, Divider, Modal } from "rsuite";
import PlaceholderParagraph from "rsuite/lib/Placeholder/PlaceholderParagraph";
import { refreshSession } from "../../services/user";

function SessionRefreshPopup() {
  const [open, setOpen] = useState(false);

  const checkSession = () => {
    const expires = window.localStorage.getItem("tokenExpire");
    const time = new Date().getTime();
    if (time > Number(expires)) {
      if (!window.localStorage.getItem("refreshToken")) {
        clearSession();
        return;
      }
      setOpen(true);
    }
  };

  const clearSession = () => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("tokenExpire");
    localStorage.removeItem("activePlan");
    localStorage.removeItem("planCanceled");
    localStorage.removeItem("planCanceledAt");
    localStorage.removeItem("planEnd");
    localStorage.removeItem("planStatus");
    sessionStorage.removeItem("tempTheme");
    sessionStorage.removeItem("tempOrg");
    window.setTimeout(() => window.location.reload(), 1000);
  };

  const refreshCurrentSession = () => {
    const token = localStorage.getItem("refreshToken");
    refreshSession(token)
      .then((response) => {
        localStorage.setItem("authToken", response.token);
        localStorage.setItem(
          "tokenExpire",
          new Date(new Date().getTime() + 3400000).getTime()
        );
        setOpen(false);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  useEffect(() => {
    window.setInterval(() => {
      checkSession();
    }, 30000);
  }, []);

  return (
    <Translation>
      {(t) => (
        <Modal
          backdrop="static"
          className="order-modal shortener-popup session-popup"
          overflow={true}
          show={open}
        >
          <Modal.Header className="order-modal-header">
            <Modal.Title>
              <h2>{t("page.session_popup.title.session_expired")}</h2>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className={`order-modal-body  `}>
            <Divider />
            <h4 className="no-margin">
              {t("page.session_popup.text.session_expired_text")}
            </h4>
          </Modal.Body>
          <Modal.Footer className="shortener-modal-footer">
            <Divider />
            <div className="shortener-actions">
              <button className="action-button-2" onClick={clearSession}>
                {t("page.session_popup.button.close_session_m")}
              </button>
              <button
                className="action-button no-margin"
                onClick={refreshCurrentSession}
              >
                {t("page.session_popup.button.refresh_session_m")}
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      )}
    </Translation>
  );
}

export default SessionRefreshPopup;

import React, { Suspense } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Popup from "../components/Popup";
import ProtectedRoute from "../components/ProtectedRoute";
import setAlert from "../helpers/notifications/alerts";
import setNotification from "../helpers/notifications/notifications";
import planManager from "../helpers/organizationPlanData";
import permissionsManager from "../helpers/permissionsManager";
///Settings
const OrganizationView = React.lazy(() =>
  import("../views/Settings/OrganizationView/index")
);
const ProfileView = React.lazy(() =>
  import("../views/Settings/ProfileView/index")
);
const SyntaxConfigView = React.lazy(() =>
  import("../views/Settings/SyntaxConfigView/index")
);
const PreferencesView = React.lazy(() =>
  import("../views/Settings/PreferencesView/index")
);
const ChangePasswordView = React.lazy(() =>
  import("../views/Settings/ChangePasswordView/index")
);
///Billing
const BillingInfoView = React.lazy(() =>
  import("../views/Billings/BillingInfoView/index")
);
const BillingPlansView = React.lazy(() =>
  import("../views/Billings/BillingPlansView/index")
);
const BillingHistoryView = React.lazy(() =>
  import("../views/Billings/BillingHistoryView/index")
);
const BillingContactsView = React.lazy(() =>
  import("../views/[DEPRECATED]/BillingContactsView/index")
);
const BillingCurrentPlanView = React.lazy(() =>
  import("../views/Billings/BillingCurrentPlanView")
);

const RoutingSettings = (props) => {
  const getBillingData = () => {
    return props.updateBilling().then((billing) => {
      return Promise.resolve({
        billing_data: billing.data,
        payment_methods: billing.paymentMethods,
      });
    });
  };

  return (
    <>
      <ProtectedRoute
        path={["/settings/organization", "/settings/organization/confirm"]}
        protectedPaths={[
          "/settings/organization",
          "/settings/organization/confirm",
        ]}
        exact
        authorized={permissionsManager("org", props.user.orgAdmin, "manageOrg")}
        redirect="/error/403"
        render={() => (
          <OrganizationView
            user={props.user}
            setError={setAlert}
            teams={props.teams}
            teamRole={props.teamRole}
            organization={props.organization}
          />
        )}
      />
      <ProtectedRoute
        path={["/settings/profile", "/settings/profile/confirm"]}
        protectedPaths={["/settings/profile", "/settings/profile/confirm"]}
        exact
        authorized={props.user}
        redirect="/error/403"
        render={() => (
          <ProfileView
            user={props.user}
            setError={setAlert}
            teams={props.teams}
            updateTeams={props.updateTeams}
            updateUser={props.updateUser}
            teamRole={props.teamRole}
            organization={props.organization}
          />
        )}
      />

      <ProtectedRoute
        path={["/settings/syntax-config", "/settings/syntax-config/confirm"]}
        protectedPaths={[
          "/settings/syntax-config",
          "/settings/syntax-config/confirm",
        ]}
        authorized={permissionsManager(
          "org",
          props.user.orgAdmin,
          "manageSyntaxConfig"
        )}
        redirect="/error/403"
        exact
        render={() => (
          <SyntaxConfigView
            setError={setAlert}
            user={props.user}
            organization={props.organization}
          />
        )}
      />
      <ProtectedRoute
        path={["/settings/preferences", "/settings/preferences/confirm"]}
        protectedPaths={[
          "/settings/preferences",
          "/settings/preferences/confirm",
        ]}
        authorized={props.user}
        redirect="/error/403"
        exact
        render={() => (
          <PreferencesView
            setError={setAlert}
            user={props.user}
            organization={props.organization}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/settings/change-password",
          "/settings/change-password/confirm",
        ]}
        protectedPaths={[
          "/settings/change-password",
          "/settings/change-password/confirm",
        ]}
        authorized={props.user}
        redirect="/error/403"
        exact
        render={() => (
          <ChangePasswordView
            setError={setAlert}
            user={props.user}
            organization={props.organization}
            teamRole={props.teamRole}
          />
        )}
      />

      {/* Billing Routes */}
      <ProtectedRoute
        path={[
          "/settings/billings/current-plan",
          "/settings/billings/current-plan/cancel-plan",
          "/settings/billings/current-plan/reactivate-plan",
          "/settings/billings/current-plan/change-card",
        ]}
        protectedPaths={[
          "/settings/billings/current-plan",
          "/settings/billings/current-plan/cancel-plan",
          "/settings/billings/current-plan/reactivate-plan",
          "/settings/billings/current-plan/change-card",
        ]}
        authorized={permissionsManager(
          "org",
          props.user.orgAdmin,
          "manageBillings"
        )}
        redirect="/error/403"
        exact
        render={() => (
          <BillingCurrentPlanView
            teamRole={props.teamRole}
            setError={setAlert}
            user={props.user}
            organization={props.organization}
            teamSelected={props.teamSelected}
            getBillingData={getBillingData}
            billingData={props.billingData.data}
            paymentMethods={props.billingData.paymentMethods}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/settings/billings/information",
          "/settings/billings/information/confirm",
          "/settings/billings/information/add-card",
          "/settings/billings/information/remove-card",
        ]}
        protectedPaths={[
          "/settings/billings/information",
          "/settings/billings/information/confirm",
          "/settings/billings/information/add-card",
          "/settings/billings/information/remove-card",
        ]}
        authorized={permissionsManager(
          "org",
          props.user.orgAdmin,
          "manageBillings"
        )}
        redirect="/error/403"
        exact
        render={() => (
          <BillingInfoView
            teamRole={props.teamRole}
            setError={setAlert}
            user={props.user}
            organization={props.organization}
            teamSelected={props.teamSelected}
            getBillingData={getBillingData}
            billingData={props.billingData.data}
            paymentMethods={props.billingData.paymentMethods}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/settings/billings/plans",
          "/settings/billings/plans/subscribe",
        ]}
        protectedPaths={[
          "/settings/billings/plans",
          "/settings/billings/plans/subscribe",
        ]}
        authorized={permissionsManager(
          "org",
          props.user.orgAdmin,
          "manageBillings"
        )}
        redirect="/error/403"
        exact
        render={() => (
          <BillingPlansView
            teamRole={props.teamRole}
            setError={setAlert}
            user={props.user}
            organization={props.organization}
            teamSelected={props.teamSelected}
            getBillingData={getBillingData}
            billingData={props.billingData.data}
            paymentMethods={props.billingData.paymentMethods}
          />
        )}
      />
      <ProtectedRoute
        path="/settings/billings/history"
        protectedPaths={["/settings/billings/history"]}
        authorized={permissionsManager(
          "org",
          props.user.orgAdmin,
          "manageBillings"
        )}
        redirect="/error/403"
        exact
        render={() => (
          <BillingHistoryView
            teamRole={props.teamRole}
            setError={setAlert}
            user={props.user}
            organization={props.organization}
            teamSelected={props.teamSelected}
          />
        )}
      />
    </>
  );
};

export default RoutingSettings;

import React, { Suspense } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Popup from "../components/Popup";
///Main
const MainView = React.lazy(() => import("../views/Main"));
const TermsConditionsView = React.lazy(() =>
  import("../views/Misc/Legal/TermsConditionsView")
);
const CookiePolicyView = React.lazy(() =>
  import("../views/Misc/Legal/CookiePolicyView")
);
const PrivacyPolicyView = React.lazy(() =>
  import("../views/Misc/Legal/PrivacyPolicyView")
);

function RoutingBilling(props) {
  const location = useLocation();

  return (
    <Suspense fallback={<></>}>
      {props.user.orgAdmin &&
        !props.plan.status &&
        !["/plan-status"].includes(location.pathname) && (
          <Redirect to="/main/plans" />
        )}
      {props.user.orgAdmin &&
        props.plan.status &&
        props.plan.status !== "active" &&
        !["/plan-status"].includes(window.location.pathname) && (
          <Redirect to="/main/plan-update" />
        )}
      {!props.user.orgAdmin && <Redirect to="/main/plan-required" />}
      <Route
        path={[
          "/main",
          "/main/subscribe",
          "/main/plans",
          "/main/plan-update",
          "/main/plan-required",
          "/plan-status",
        ]}
        exact
        render={() => (
          <MainView
            setUser={(token, plan) => {
              props.appInitialization(token);
            }}
            // setError={this.setError}
            organization={props.organization}
            billing_data={props.billingData.data}
            payment_methods={props.billingData.paymentMethods}
          />
        )}
      />
      <Route
        path={"/terms-and-conditions"}
        exact
        render={() => <TermsConditionsView />}
      />
      <Route
        path={"/privacy-policy"}
        exact
        render={() => <PrivacyPolicyView />}
      />
      <Route
        path={"/cookie-policy"}
        exact
        render={() => <CookiePolicyView />}
      />
    </Suspense>
  );
}

export default RoutingBilling;

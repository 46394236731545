import React from "react";
import { Route, Redirect, useRouteMatch } from "react-router-dom";

const ProtectedRoute = ({ authorized, redirect, protectedPaths, ...props }) => {
  const protectedMatch = useRouteMatch(protectedPaths);

  if (!authorized && protectedMatch ? protectedMatch.isExact : false) {
    return <Redirect to={redirect} />;
  } else {
    return <Route {...props} />;
  }
};

export default ProtectedRoute;

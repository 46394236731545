import React, { Suspense } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Popup from "../components/Popup";
import ProtectedRoute from "../components/ProtectedRoute";
import setAlert from "../helpers/notifications/alerts";
import setNotification from "../helpers/notifications/notifications";
import planManager from "../helpers/organizationPlanData";
import permissionsManager from "../helpers/permissionsManager";
///Teams
const TeamManagementView = React.lazy(() =>
  import("../views/Teams/TeamManagementView/index")
);
const UserManagementView = React.lazy(() =>
  import("../views/Teams/UserManagementView/index")
);

const RoutingTeams = (props) => {
  return (
    <>
      <ProtectedRoute
        path={[
          "/teams/team-management",
          "/teams/team-management/create",
          "/teams/team-management/edit",
          "/teams/team-management/delete",
        ]}
        authorized={permissionsManager(
          "org",
          props.user.orgAdmin,
          "manageAllTeams"
        )}
        redirect="/error/403"
        exact
        render={() => (
          <TeamManagementView
            setError={setAlert}
            user={props.user}
            updateTeams={props.updateTeams}
            organization={props.organization}
            plan={props.plan}
            teamRole={props.teamRole}
          />
        )}
      />
      <ProtectedRoute
        path={[
          "/teams/user-management",
          "/teams/user-management/create",
          "/teams/user-management/edit",
          "/teams/user-management/delete",
          "/teams/user-management/resend-invite",
        ]}
        authorized={
          permissionsManager("org", props.user.orgAdmin, "manageAllUsers") ||
          permissionsManager("team", props.teamRole, "manageTeamUsers")
        }
        redirect="/error/403"
        exact
        render={() => (
          <UserManagementView
            setError={setAlert}
            user={props.user}
            teams={props.teams}
            setNotification={setNotification}
            organization={props.organization}
            plan={props.plan}
            teamRole={props.teamRole}
          />
        )}
      />
    </>
  );
};

export default RoutingTeams;

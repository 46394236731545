import React, { Suspense } from "react";
import { Redirect, Route, useLocation } from "react-router-dom";
import Popup from "../components/Popup";
import ProtectedRoute from "../components/ProtectedRoute";
import setAlert from "../helpers/notifications/alerts";
import setNotification from "../helpers/notifications/notifications";
import planManager from "../helpers/organizationPlanData";
import permissionsManager from "../helpers/permissionsManager";
import HelpView from "../views/Misc/HelpView";
import PrivacyPolicyView from "../views/Misc/Legal/PrivacyPolicyView";
import TermsConditionsView from "../views/Misc/Legal/TermsConditionsView";
import Error404 from "../views/Misc/Errors/index";
///Trash
const CampaignLinksTrashView = React.lazy(() =>
  import("../views/Trash/CampaignAndLinks/index")
);
const TemplatesTrashView = React.lazy(() =>
  import("../views/Trash/Templates/index")
);

const RoutingLegal = (props) => {
  return (
    <>
      {/* Legal & Error Routes */}
      <Route
        path={"/terms-and-conditions"}
        exact
        render={() => <TermsConditionsView logged />}
      />
      <Route
        path={"/privacy-policy"}
        exact
        render={() => <PrivacyPolicyView logged />}
      />
      {/*
      <Route path="/help" exact render={() => <HelpView user={props.user} />} />
       */}
      <Route path="/error/426" exact render={() => <Error404 code={426} />} />
      <Route path="/error/403" exact render={() => <Error404 code={403} />} />
      <Route path="/error/404" exact render={() => <Error404 code={404} />} />
    </>
  );
};

export default RoutingLegal;

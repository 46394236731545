import { Notification } from "rsuite";

const setDebugNotification = (error) => {
  //return if debug is not active in localStorage
  if (!sessionStorage.getItem("debugMode")) return;

  console.log("err", error);
  if (error && error.message) {
    error = error.message;
  }

  //Add the error to a sessionStorage array
  let debugErrors = JSON.parse(sessionStorage.getItem("debugErrors"));
  if (!debugErrors) debugErrors = [];
  debugErrors.push({
    error: error,
    date: new Date(),
    path: window.location.pathname,
  });
  sessionStorage.setItem("debugErrors", JSON.stringify(debugErrors));

  Notification.open({
    title: "#DEBUGERROR",
    description: (
      <div className="general-notification">
        <h4>{error}</h4>
      </div>
    ),
    duration: 0,
  });
};

export { setDebugNotification };

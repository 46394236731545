import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";

function ErrorBoundary(props) {
  return (
    <Translation>
      {(t) => (
        <div className="error-container">
          <div>
            <h1>500</h1>
            <h2>{t("An error has ocurred.")}</h2>
            <Link to="/">
              <button className="action-button" onClick={props.clearError}>
                {t("VOLTAR")}
              </button>
            </Link>
          </div>
          <img src="/illustrations/500.svg" />
        </div>
      )}
    </Translation>
  );
}

class ErrorBoundary2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
  }

  render() {
    return (
      <Translation>
        {(t) => (
          <div className="error-container">
            <div>
              <h1>500</h1>
              <h2>{t("An error has ocurred.")}</h2>
              <button className="action-button">{t("VOLTAR")}</button>
            </div>
            <img src="/illustrations/500.svg" />
          </div>
        )}
      </Translation>
    );
  }
}

export default ErrorBoundary;

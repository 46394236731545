import React from "react";
import { Translation } from "react-i18next";
import { Link } from "react-router-dom";

function Error(props) {
  return (
    <Translation>
      {(t) => (
        <div className="error">
          {props.code === 404 && (
            <div className="error-container">
              <div>
                <h1>404</h1>
                <h2>{t("multiple.alert.404")}</h2>
                <button
                  className="action-button"
                  onClick={() => {
                    window.history.back();
                  }}
                  style={{ width: "50%" }}
                >
                  VOLTAR
                </button>
              </div>
              <img src="/illustrations/404.svg" />
            </div>
          )}
          {props.code === 403 && (
            <div className="error-container">
              <div>
                <h1>403</h1>
                <h2>{t("multiple.alert.403")}</h2>{" "}
                <button
                  className="action-button"
                  onClick={() => {
                    window.history.back();
                  }}
                  style={{ width: "50%" }}
                >
                  VOLTAR
                </button>
              </div>
              <img src="/illustrations/403.svg" />
            </div>
          )}
          {props.code === 426 && (
            <div className="error-container">
              <div>
                <h1 style={{ fontSize: "60px" }}>Faça upgrade ao seu plano!</h1>
                <h2>
                  {t(
                    "Desbloqueie esta e outras funcionalidades, atualize o seu plano hoje!"
                  )}
                </h2>
                <Link to="/settings/billings/plans" style={{ width: "50%" }}>
                  <button
                    className="action-button"
                    onClick={props.clearError}
                    style={{ width: "50%" }}
                  >
                    {t("UPGRADE")}
                  </button>
                </Link>
              </div>
              <img
                src="/illustrations/426.svg"
                style={{ width: "30%", maxWidth: "400px" }}
              />
            </div>
          )}
        </div>
      )}
    </Translation>
  );
}

export default Error;

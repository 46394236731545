import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import translationPT from "./locales/pt/url.json";
import translationGB from "./locales/gb/url.json";
import translationEN from "./locales/en/url.json";
import translationES from "./locales/es/url.json";
import translationCA from "./locales/ca/url.json";
import translationFR from "./locales/fr/url.json";
import translationDE from "./locales/de/url.json";
import translationIT from "./locales/it/url.json";
import translationPL from "./locales/pl/url.json";
import translationRO from "./locales/ro/url.json";
import translationRU from "./locales/ru/url.json";
import translationTR from "./locales/tr/url.json";

// the translations "pt", "gb", "us", "es", "de", "it", "pl", "ro", "ru", "tr"
const resources = {
  pt: {
    translation: translationPT,
  },
  en: {
    translation: translationEN,
  },
  es: {
    translation: translationES,
  },
  fr: {
    translation: translationFR,
  },
  pl: {
    translation: translationPL,
  },
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: "en",
    supportedLngs: ["pt", "en", "es", "fr", "pl"],
    nonExplicitSupportedLngs: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;

//Styles Import
import "./App.less";
import "./views/Settings/ProfileView/style.css";
import "./views/Links/ParameterManagementView/style.css";
import "./views/Campaigns/CampaignTaxonomyView/style.css";
import "./views/[DEPRECATED]/ParameterDependenciesManagementView/style.css";
import "./views/Settings/SyntaxConfigView/style.css";
import "./views/Links/Parameters/style.css";
import "./components/Popup/style.css";
import "./components/ParameterList/style.css";
import "./components/OrderPopup/style.css";
import "rsuite/lib/styles/index.less";
//Packages Import
import i18n from "./i18n";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { getUserBillingData, getUserPaymentData } from "./services/user";
//Helpers Import
import ErrorBoundary from "./helpers/errorBoundary";
import colorShemeData from "./helpers/colorSchemes.json";
//Components Import
import React, { Component, Suspense, useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
  useLocation,
} from "react-router-dom";
import Sidenav from "./components/Navigation/SlidingDrawer/Sidenav";
import Navbar from "./components/Navigation/NavBar";

import SessionRefreshPopup from "./components/SessionRefreshPopup";
import { ProductFruits } from "react-product-fruits";
import resetAuthData from "./helpers/authDataReset";
import { getUserInformation } from "./helpers/dataFunctions/user";
import { getOrganizationInformation } from "./helpers/dataFunctions/organization";
import { getTeamsInformation } from "./helpers/dataFunctions/teams";
import RoutingAuthentication from "./routing/authentication";
import RoutingBilling from "./routing/billing";
import setAlert from "./helpers/notifications/alerts";

import RoutingLinks from "./routing/links";
import RoutingCampaigns from "./routing/campaigns";
import RoutingTeams from "./routing/teams";
import RoutingTrash from "./routing/trash";
import RoutingSettings from "./routing/settings";
import RoutingLegal from "./routing/legal&error";
import DebugMenu from "./components/DebugMenu";
import { PiwikGlobals } from "./helpers/piwikEvents";

const Dashboard = React.lazy(() => import("./views/Dashboard/index"));

//FUNCTIONS START

//Versioning
const version = "1.1";
const versionDate = "20231204";

//Bugsnag Initialization
Bugsnag.start({
  apiKey: "0aa4463caeb453cd02bb1d1db1ace9c8",
  plugins: [new BugsnagPluginReact()],
  appVersion: version,
});
const ErrorBoundaryBugsnag =
  Bugsnag.getPlugin("react").createErrorBoundary(React);
console.log(`URL Builder Online v${version} - ${versionDate}`);

//Function component rewrite of the App class component
const App = () => {
  //Initial State
  const [user, setUser] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [teams, setTeams] = useState(null);
  const [teamSelected, setTeamSelected] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [theme, setTheme] = useState(
    window.sessionStorage.getItem("tempTheme") || "klein_blue"
  ); //If a theme other than default has been applied, load it from temp so it appears instantly on page refresh
  const [mode, setMode] = useState("light");
  const [error, setError] = useState("");
  const [auth2Loaded, setAuth2Loaded] = useState(false);
  const [requiresPlan, setRequiresPlan] = useState(false);
  const [billingData, setBillingData] = useState({
    data: null,
    paymentMethods: null,
  });
  const [plan, setPlan] = useState(null);
  const [isLogged, setIsLogged] = useState(false);
  const [teamRole, setTeamRole] = useState(null);

  //Function that runs on page load
  useEffect(() => {
    appInitialization();
  }, []);

  const appInitialization = () => {
    setLoaded(false);
    //Apply temporary personalization
    applyPersonalization(
      window.sessionStorage.getItem("tempTheme"), //Get temporary theme from sessionStorage
      window.sessionStorage.getItem("tempMode"), //Get temporary mode from sessionStorage
      window.sessionStorage.getItem("tempLang") ||
        new URLSearchParams(window.location.search).get("lang") //Get temporary language from sessionStorage or URL
    );

    //Check if there is an auth token and if its expiration is whitin the next 2 minutes
    let authToken = window.localStorage.getItem("authToken");
    if (authToken) {
      let tokenExpire = window.localStorage.getItem("tokenExpire");
      //If there is an auth token, check if it is expired
      if (!tokenExpire) {
        resetAuthData();
        setLoaded(true);
        return;
      }
      let now = new Date();
      let expire = new Date(Number(tokenExpire));
      //console.log("Token Expire: " + expire);
      //If the token is expired, remove it from sessionStorage
      if (now > expire) {
        resetAuthData();
        setLoaded(true);
        return;
      }
      //console.log("Token is valid");
      setIsLogged(true);
      getMainInformation(authToken);
    } else {
      setLoaded(true);
    }
  };

  const applyPersonalization = (theme, mode, lang) => {
    //Apply theme
    if (theme) {
      setTheme(theme);
    }
    //Apply mode
    if (mode) {
      setMode(mode);
    }
    //Apply language
    if (lang) {
      i18n.changeLanguage(lang);
    }
  };

  //Function that applies selected color scheme and mode to page
  useEffect(() => {
    //Get theme data
    const colorScheme = colorShemeData.color_schemes;
    const colorMode = colorShemeData.color_modes;

    //Set theme property lists
    const themeProperties = ["color1", "color2", "color3", "colorButton"];
    const modeProperties = ["colorBG", "colorFont", "colorDrawer"];

    //Apply theme variables
    for (const property of themeProperties) {
      document.documentElement.style.setProperty(
        `--${property}`,
        colorScheme[theme][property]
      );
    }
    //Apply mode variables
    for (const property of modeProperties) {
      document.documentElement.style.setProperty(
        `--${property}`,
        colorMode[mode][property]
      );
    }
  }, [theme, mode]);

  //Function that fetches main information
  const getMainInformation = (authToken) => {
    //Get user and organization data
    Promise.all([
      getUserInformation(authToken),
      getOrganizationInformation(authToken),
    ])
      .then((responses) => {
        //console.log("Main information loaded");
        const dbUser = responses[0];
        const dbOrganization = responses[1];

        //TODO: Refactor to enable multiple organizations

        //Check if the organization exists in the user orgs list
        const userOrg = dbUser.orgs.find((org) => org.id === dbOrganization.id);
        if (!userOrg) {
          return Promise.reject("User organization not found");
        }

        //Check if user is admin and set it in the user object
        dbUser.orgAdmin = userOrg.role === "admin";
        userOrg.name = dbOrganization.name;

        //Set user and organization data
        setUser(dbUser);
        setOrganization(dbOrganization);

        //Apply user personalization or any session based personalization
        applyPersonalization(
          window.sessionStorage.getItem("tempTheme") ||
            dbUser.default_color_scheme,
          window.sessionStorage.getItem("tempMode") || dbUser.mode,
          window.sessionStorage.getItem("tempLang") ||
            new URLSearchParams(window.location.search).get("lang") ||
            dbUser.language
        );

        //Check if the organization has an active plan
        const plan = {
          plan: window.localStorage.getItem("activePlan"),
          cycle: window.localStorage.getItem("activeCycle"),
          status: window.localStorage.getItem("planStatus"),
        };
        setPlan(plan);
        if (plan.plan && plan.status === "active") {
          //Handle data loading
          //console.log("Organization has an active plan");
          return handleAdditionalData(authToken, dbUser, dbOrganization);
        } else {
          //Handle plan acquisition
          //console.log("Organization does not have an active plan");
          return handleBilling(authToken, dbUser, dbOrganization, true);
        }
      })
      .then((response) => {
        //console.log("Additional data loaded");
        setLoaded(true);
      })
      .catch((error) => {
        //console.log(error);
      });
  };

  const handleBilling = (
    authToken,
    mainUser,
    mainOrganization,
    requiresPlan
  ) => {
    //Use the authToken, user and organization passed as parameters or the ones stored in the state
    authToken = authToken || window.localStorage.getItem("authToken");
    mainUser = mainUser || user;
    mainOrganization = mainOrganization || organization;

    if (requiresPlan) setRequiresPlan(true);
    //Find the user organization
    const userOrg = mainUser.orgs.find((org) => org.id === mainOrganization.id);
    if (!userOrg) {
      return Promise.reject("User organization not found");
    }
    //Check if the user is an admin
    if (!userOrg.role === "admin") {
      return Promise.reject("User is not an admin");
    }
    return Promise.all([
      getUserBillingData(authToken, mainOrganization.id),
      getUserPaymentData(authToken, mainOrganization.id),
    ])
      .then((responses) => Promise.resolve(responses))
      .then((responses) => {
        let billing = {
          data: {
            ...responses[0],
            default_payment_method: responses[1].defaulCard,
          },
          paymentMethods: responses[1].cards || [],
        };
        setBillingData(billing);
        return Promise.resolve(billing);
      })
      .catch((error) => {
        //console.log(error);
        return Promise.reject(error);
      });
  };

  const handleAdditionalData = (authToken, mainUser, mainOrganization) => {
    //Use the authToken, user and organization passed as parameters or the ones stored in the state
    authToken = authToken || window.localStorage.getItem("authToken");
    mainUser = mainUser || user;
    mainOrganization = mainOrganization || organization;

    //Set the requires plan flag to false
    setRequiresPlan(false);

    //Get team data
    return getTeamsInformation(authToken, mainUser, mainOrganization)
      .then((response) => {
        //console.log("Teams loaded");
        //Get the last used team
        let lastTeam = window.localStorage.getItem("defaultTeam");
        let team = response.find(
          (team) => team.id === lastTeam && team.status === "active"
        );
        //console.log(team);
        //Check if the last team belongs to the user and if it is active
        lastTeam = team ? team : response[0];

        //Set the last used team as the selected team
        setTeamSelected(lastTeam);
        let role = mainUser.orgAdmin
          ? "leader"
          : mainUser.teams.find((team) => team.id === lastTeam.id).role;
        setTeamRole(role);
        //Set teams data
        setTeams(response);
        return Promise.resolve();
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const changeTeam = (teamId) => {
    let selected = teams.find((team) => team.id === teamId);

    if (!selected) return;

    //Update default team on localStorage for instant load on page refreshes
    //console.log("changed", teamId);
    window.localStorage.setItem("defaultTeam", teamId);

    //Remove any campaign filter currently on sessionStorage
    window.sessionStorage.removeItem("campaign-filter");

    setTeamSelected(selected);
    let role = user.orgAdmin
      ? "leader"
      : user.teams.find((team) => team.id === selected.id).role;
    setTeamRole(role);
  };

  const routingData = {
    user,
    teams,
    organization,
    teamRole,
    teamSelected,
    plan: plan && plan.plan,
    updateTeams: handleAdditionalData,
  };

  return (
    <div className="App">
      {isLogged ? (
        <>
          {requiresPlan ? (
            <>
              {loaded ? (
                <BrowserRouter>
                  {process.env.REACT_APP_ENV === "qld" ? <DebugMenu /> : <></>}
                  <PiwikGlobals user={user} plan={plan} />
                  <RoutingBilling
                    {...{
                      organization,
                      billingData,
                      user,
                      plan,
                      appInitialization,
                    }}
                  />
                  <RoutingLegal />
                </BrowserRouter>
              ) : (
                <></>
              )}
            </>
          ) : (
            <>
              <BrowserRouter>
                {process.env.REACT_APP_ENV === "qld" ? <DebugMenu /> : <></>}
                <PiwikGlobals user={user} plan={plan} />
                <Navbar
                  user={user}
                  theme={theme}
                  setTheme={applyPersonalization}
                />
                {user ? <></> : <></>}
                <SessionRefreshPopup />
                <div id="main">
                  <Sidenav
                    user={user}
                    organization={organization}
                    changeTeam={changeTeam}
                    teams={teams}
                    teamSelected={teamSelected}
                    teamRole={teamRole}
                    orgRole={user && user.orgAdmin}
                    plan={user && plan && plan.plan}
                    version={version}
                    versionDate={versionDate}
                  />

                  {loaded ? (
                    <div className="view small">
                      <ProductFruits
                        workspaceCode="B8lxqMyjabBtPDK5"
                        language={i18n.language}
                        user={{
                          username: user.email,
                          signUpAt: user.createdAt,
                          role: user.orgAdmin ? "admin" : "member",
                          props: {
                            teamRole: teamRole,
                            activePlan: plan && plan.plan,
                            teamTaxonomy: teamSelected.campaign_taxonomy,
                            env: process.env.REACT_APP_ENV,
                          },
                        }}
                      />
                      <Suspense fallback={<></>}>
                        <ErrorBoundaryBugsnag FallbackComponent={ErrorBoundary}>
                          <Switch>
                            <Redirect exact from="/" to="/dashboard" />
                            <>
                              {[
                                "/confirm",
                                "/main",
                                "/plan-status",
                                "/recover-password",
                                "/",
                              ].includes(window.location.pathname) && (
                                <Redirect to="/dashboard" />
                              )}
                              <Route
                                path={["/dashboard", "/dashboard/reminder"]}
                                exact
                                render={() => (
                                  <Dashboard
                                    user={user}
                                    setError={setAlert}
                                    teams={teams}
                                    organization={organization}
                                    teamSelected={teamSelected}
                                    plan={plan && plan.plan}
                                  />
                                )}
                              />

                              <RoutingCampaigns
                                {...routingData}
                                key={"campaigns"}
                              />
                              <RoutingLinks {...routingData} key={"links"} />
                              <RoutingTeams {...routingData} key={"teams"} />
                              <RoutingTrash {...routingData} key={"trash"} />
                              <RoutingSettings
                                {...routingData}
                                billingData={billingData}
                                updateBilling={handleBilling}
                                key={"billing"}
                              />
                              <RoutingLegal />
                            </>
                          </Switch>
                        </ErrorBoundaryBugsnag>
                      </Suspense>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </BrowserRouter>
            </>
          )}
        </>
      ) : (
        <>
          {loaded ? (
            <>
              <BrowserRouter>
                {process.env.REACT_APP_ENV === "qld" ? <DebugMenu /> : <></>}
                <PiwikGlobals user={user} plan={plan} />
                <RoutingAuthentication
                  {...{ organization, billingData, appInitialization, version }}
                />
                <RoutingLegal />
              </BrowserRouter>
            </>
          ) : (
            <></>
          )}
        </>
      )}
    </div>
  );
};

export default App;
